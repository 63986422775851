import { array , number } from 'io-ts';

import { V2 } from '~/src/geometry/vector';
import { Requestor } from '~/src/api/Requestor';

import { Config , Simple , RecKey } from './Model';

export const snumber = ( s : string ) : null | number => {
	const value : number = Number( s );
	return isNaN( value ) ? null : value;
};

export const solveSimple2D = ( req : Requestor ) => ( cons : Simple< number > ) : Promise< Config< number , V2 >[] > =>
	req.request( array( Config( RecKey( snumber , number ) )( V2 ) ) )( 'geosol/simple/2D/' , 'POST' , cons );
