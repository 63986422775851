.sketch ,
.frameViews ,
.pieceViews ,
.clickViews ,
.pointViews ,
.rulerViews ,
.point ,
.piece ,
.frags ,
.frame ,
.ruler ,
.first ,
.final ,
.visual ,
.handle ,
.selected {
  visibility: visible;
}


/* Sketch */

.sketch {
  stroke: currentColor;
  stroke-width: 2px;
  stroke-linecap: round;

  box-sizing: border-box;
}

.sketch text {
  fill: currentColor;
  stroke: none;
  font-size: calc( var( --fntsze ) + 1px );
}


/* Point */

.point .visual ,
.point .handle {
  stroke: transparent;
  stroke-width: 0px;
}

.point .visual {
  r: 5px;
  fill: orange;
}

.point .handle {
  r: 15px;
  fill: transparent;
}

.point .visual:has( + .handle:hover ) {
  r: 10px;
}


/* Frame */

.frame .visual ,
.frame .handle {
  fill: transparent;
}

.frame .visual {
  pointer-events: none;
}

.frame .handle {
  stroke: transparent;
  stroke-width: 0;
}

.frame .handle:hover {
  fill: #ffa50033;
}


/* Piece */

.piece.visual ,
.piece.handle {
  fill: transparent;
}

.piece.visual {
  stroke: transparent;
  stroke-width: 0;
  pointer-events: none;
}

.piece.handle {
  stroke: transparent;
  stroke-width: 30px;
  pointer-events: stroke;
}

.piece.handle:hover {
  stroke: #0005;
}

.piece.visual.selected {
  stroke: yellow;
  stroke-width: 2px;
}


/* Ruler */
.ruler .visual,
.ruler .whisker {
  stroke: #fff2 !important;
}

.ruler.angle > .handle {
  fill: transparent;
}

.ruler.angle > .handle:hover {
  fill: #ffa50033;
}

.ruler.length > .handle {
  stroke: transparent;
  stroke-width: 30px;
  pointer-events: stroke;
}

.ruler.length > .handle:hover {
  stroke: #0005;
}

.whisker.standard {
  stroke-width: 2px;
}

.whisker.extended {
  stroke-width: 1px;
  shape-rendering: crispEdges;
  stroke-dasharray: 5px 3px;
}


/* Reading */
.reading .handle {
  r: 15px;
  fill: transparent;
  stroke-width: 0;
}

.reading .handle:hover {
  fill: #0005;
}

.reading .prefix {
  visibility: visible;
}

.reading .value {
  fill: #fff;
}

.reading .unit {
  fill: currentColor;
}

.reading .visual:has( + .handle:hover ) .value {
  fill: yellow;
}

