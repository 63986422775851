import { WhiskrConfig , HandleConfig } from './Config';

/**
 * The preset type used to specify some rendering defaults for a ruler.
 */
export interface RulerPreset< RX , LX > extends HandleConfig< RX > {
	whisk : WhiskrConfig;
	label : HandleConfig< LX >;
}

/**
 * The preset type used to specify some rendering defaults for points, chunks,
 * frames and rulers.
 */
export interface SketchPreset< NX , CX , FX , RX , LX > {
	point : HandleConfig< NX >;
	chunk : HandleConfig< CX >;
	frame : HandleConfig< FX >;
	ruler : RulerPreset< RX , LX >;
}

export const preset : SketchPreset< any , any , any, any, any > =
	{ point :
			{ visual : { render : true }
				, handle : { render : true }
			}
	, chunk :
			{ visual : { render : true }
				, handle : { render : true }
			}
	, frame :
			{ visual : { render : true }
				, handle : { render : true }
			}
	, ruler :
			{ whisk :
				{ standard : { render : true }
					, extended : { render : true }
				}
			, label :
				{ visual : { render : true }
					, handle : { render : true }
				}
			, visual : { render : true }
			, handle : { render : true }
			}
	};
