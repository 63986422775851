import { Mod , comp } from '~/src/base/Function';
import { Optic , dot , where , set , get } from '~/src/base/Optic';

import * as Geo from '~/src/geosol/Model';
import { Angle , Distance , Selector } from '~/src/geosol/Model';


export const dtr = ( a : number ) => a * Math.PI / 180;


export interface Len {
	type : 'Len';
	dist : Distance< number >;
}

export const len = ( x : number , y : number ) => ( l : number ) : Len =>
	( { type : 'Len' , dist : Geo.dst( x , y , l ) } );

export const dist : Optic< Len , Distance< number > > = dot( 'dist' );

export const lval : Optic< Len , number > = comp( dist , Geo.dval );

export const lenN : Optic< Len , number > = comp( dist , Geo.dstN );



export type Wise = 'CW' | 'CCW';

export const togWse = ( wise : Wise ) : Wise => wise === 'CW' ? 'CCW' : 'CW';

export interface Ang {
	type : 'Ang';
	wise : Wise;
	degs : number;
	angl : Angle< number >;
}

export const ang = ( x : number , y : number , z : number ) => ( a : number ) => ( w : Wise ) : Ang =>
	( { type : 'Ang' , wise : w , degs : a , angl : Geo.ang( x , y , z , dtr( a ) ) } );

export const wise : Optic< Ang , Wise > = dot( 'wise' );
export const degs : Optic< Ang , number > = dot( 'degs' );
export const angl : Optic< Ang , Angle< number > > = dot( 'angl' );
export const angN : Optic< Ang , number > = comp( angl , Geo.angN );

export const aval = ( f : Mod< number > ) => ( ang : Ang ) : Ang => {
	const val : number = f( ang.degs );
	const res : Ang = comp( set( degs )( val ) , set( comp( angl , Geo.aval<number> ) )( dtr( val ) ) )( ang );
	return res === ang ? ang : res;
};

export const ang2sel = ( { wise , angl : { value : p } } : Ang ) : Selector< number > => ( {
	type : wise,
	value : [ p[0] , p[1] , p[2] ],
} );


export type Raw = Len | Ang;

export const isLen = ( raw : Raw ) : raw is Len =>
	raw.type === 'Len';

export const isAng = ( raw : Raw ) : raw is Ang =>
	raw.type === 'Ang';

export const rawVal = ( f : Mod< number > ) => ( raw : Raw ) : Raw =>
	isLen( raw )
		? comp( rawLen , lval )( f )( raw )
		: comp( rawAng , aval )( f )( raw )
;

export const rawLen : Optic< Raw , Len > = where( isLen );

export const rawAng : Optic< Raw , Ang > = where( isAng );

export const rawWse : Optic< Raw , Wise > = comp( rawAng , wise );

export const rawMaxIdx = ( raw : Raw ) : number =>
	isLen( raw )
		? Math.max( ...get( Geo.dstN )( raw.dist ) )
		: Math.max( ...get( Geo.angN )( raw.angl ) )
		;

export const rawsMaxIdx = ( raws : Raw[] ) : number =>
	Math.max( ...raws.map( rawMaxIdx ) );

export interface Rnd {
	type : 'Rnd';
	root : number;
	first : number;
	final : number;
	radius : number;
}

export const rnd = ( first : number , root : number , final : number ) => ( radius : number ) : Rnd =>
	( { type : 'Rnd' , first , root , final , radius } );

export const rval : Optic< Rnd , number > = dot( 'radius' );

export const rndN = ( f : Mod< number > ) => ( rnd : Rnd ) : Rnd => {
	const { root , first , final , radius } = rnd;
	const rt : number = f( root );
	const fs : number = f( first );
	const fn : number = f( final );

	if ( root === rt && first === fs && final === fn )
		return rnd;

	return { type : 'Rnd' , root : rt , first : fs , final : fn , radius };
};

export type Node = 'First' | 'Final';
export type Side = 'Left' | 'Right';

export interface Out {
	type : 'Out';
	first : number;
	final : number;
	value : number;
	node : Node;
	side : Side;
}

export const out = ( first : number , final : number , value : number , node : Node , side : Side ) : Out =>
	( { type : 'Out' , first , final , value , node , side } );

export const oval : Optic< Out , number > = dot( 'value' );

export const outN = ( f : Mod< number > ) => ( out : Out ) : Out => {
	const { first , final , ...rest } = out;
	const fs : number = f( first );
	const fn : number = f( final );

	if ( first === fs && final === fn )
		return out;

	return { ...rest , first : fs , final : fn };
};

export type Con = Ang | Len | Rnd | Out;

export const cIsLen = ( con : Con ) : con is Len =>
	con.type === 'Len';

export const cIsAng = ( con : Con ) : con is Ang =>
	con.type === 'Ang';

export const cIsRnd = ( con : Con ) : con is Rnd =>
	con.type === 'Rnd';

export const cIsOut = ( con : Con ) : con is Out =>
	con.type === 'Out';

export const conN = ( f : Mod< number > ) => ( con : Con ) : Con => {
	switch ( con.type ) {
	case 'Len':
		return lenN( f )( con );
	case 'Ang':
		return angN( f )( con );
	case 'Rnd':
		return rndN( f )( con );
	case 'Out':
		return outN( f )( con );
	}
};

export const conVal = ( f : Mod< number > ) => ( con : Con ) : Con => {
	switch ( con.type ) {
	case 'Len':
		return comp( conLen , lval )( f )( con );
	case 'Ang':
		return comp( conAng , aval )( f )( con );
	case 'Rnd':
		return comp( conRnd , rval )( f )( con );
	case 'Out':
		return comp( conOut , oval )( f )( con );
	}
};

export const conLen : Optic< Con , Len > = where( cIsLen );
export const conAng : Optic< Con , Ang > = where( cIsAng );
export const conRnd : Optic< Con , Rnd > = where( cIsRnd );
export const conOut : Optic< Con , Out > = where( cIsOut );
export const conWse : Optic< Con , Wise > = comp( conAng , wise );

export const currIdx = ( idx : number ) => ( con : Con ) : number =>
	Math.max( idx , Math.max( ...( get( conN )( con ) ) ) + 1 );

export const nextIdx = ( idx : number ) => ( con : Con ) : number => {
	const cix : number = currIdx( idx )( con );

	if ( cIsRnd( con ) )
		return cix + 6;

	if ( cIsOut( con ) )
		return cix + 1;

	return cix;
};


export const rnd2raw = ( ix : number ) => ( rnd : Rnd ) : Raw[] => {
	const idx : number = currIdx( ix )( rnd );
	const { root , first , final , radius } = rnd;

	return [
		len( idx + 0 , root )( radius ),
		ang( idx + 0 , root , final )( 90 )( 'CCW' ),
		len( root , idx + 1 )( radius ),
		ang( first , root , idx + 1 )( 90 )( 'CCW' ),

		ang( idx + 2 , idx + 0 , root )( 90 )( 'CCW' ),
		ang( root , idx + 1 , idx + 2 )( 90 )( 'CCW' ),

		len( idx + 2 , idx + 3 )( radius ),
		ang( idx + 2 , idx + 3 , root )( 90 )( 'CCW' ),

		len( idx + 2 , idx + 4 )( radius ),
		ang( root , idx + 4 , idx + 2 )( 90 )( 'CCW' ),

		len( idx + 2 , idx + 5 )( radius ),
		ang( idx + 2 , idx + 5 , root )( 180 )( 'CCW' ),
	];
};

export const out2raw = ( ix : number ) => ( out : Out ) : Raw[] => {
	const idx : number = currIdx( ix )( out );
	const { first , final , value , side , node } = out;
	const off : number = node === 'First' ? first : final;

	const acon : Ang = node === 'Final'
		? ang( first , final , idx )( 90 )( side === 'Left' ? 'CCW' : 'CW' )
		: ang( final , first , idx )( 90 )( side === 'Left' ? 'CW' : 'CCW' );

	return [ len( off , idx )( value ) , acon ];
};

export const con2raw = ( idx : number ) => ( con : Con ) : Raw[] => {
	switch ( con.type ) {
	case 'Len':
		return [ con ];
	case 'Ang':
		return [ con ];
	case 'Rnd':
		return rnd2raw( idx )( con );
	case 'Out':
		return out2raw( idx )( con );
	}
};

export const cons2raws = ( idx : number ) => ( cons : Con[] ) : Raw[] => {
	let nidx : number = idx;
	const raws : Raw[] = [];

	for ( const con of cons )
	{
		const batch : Raw[] = con2raw( nidx )( con );
		nidx = rawsMaxIdx( batch ) + 1;
		batch.forEach( x => raws.push( x ) );
	}

	return raws;
};
