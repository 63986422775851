import { P } from '~/src/geometry/point';
import { Mod } from '~/src/base/Function';
import { Optic , dot } from '~/src/base/Optic';

import { Config } from '~/src/geosol/Model';

export type Pair< A , B > = [ A , B ];

export const fst = < A , B >( [ a , _ ] : Pair< A , B > ) : A => a;
export const snd = < A , B >( [ _ , b ] : Pair< A , B > ) : B => b;


export interface Index< V > {
	next : number;
	data : Map< number , V >;
}

export const next = < V >() : Optic< Index< V > , number > => dot( 'next' );
export const data = < V >() : Optic< Index< V > , Map< number , V > > => dot( 'data' );
export const item = ( k : number ) => < V >( f : Mod< undefined | V > ) => ( i : Index< V > ) : Index< V > => {
	const cv : undefined | V = i.data.get( k );

	if ( !cv ) return i;

	const nv : undefined | V = f( cv );

	if ( !nv || nv === cv ) return i;

	const data : Map< number , V > = new Map( i.data );

	data.set( k , nv );

	return { ...i , data };
};

export default Index;

export const make = < V >( data ?: V[] ) : Index< V > =>
	( {
		next : data?.length ?? 0,
		data : new Map( data?.map( ( v , i ) => [ i , v ] ) ),
	} );

export const push = < V >( ...vals : V[] ) => ( index : Index< V > ) : Index< V > => {
	let next : number = index.next;
	const data : Map< number , V > = new Map( index.data );
	for ( const v of vals )
		data.set( next++ , v );
	return { next , data };
};

export const c2m = < V >( config : Config< number , V > ) : Map< number , P< V > > => {
	const result : Map< number , P< V > > = new Map();

	Object.keys( config ).forEach( ( k : string ) => {
		const key : number = Number( k );

		if ( isNaN( key ) ) {
			console.warn( 'Invalid \'Config\' key:' , k );
			return;
		}

		const val : undefined | P< V > = config[ key ];

		if ( !val ) {
			console.warn( 'Invalid \'Config\' val:' , val );
			return;
		}

		result.set( key , val );
	} );

	return result;
};

export const c2i = < V >( config : Config< number , V > ) : Index< P< V > > => {
	const data : Map< number , P< V > > = c2m( config );
	const next : number = data.size === 0 ? 0 : Math.max( ...data.keys() ) + 1;

	return { next , data };
};
