import { P2 } from '~/src/geometry/point';
import { V2 } from '~/src/geometry/vector';
import { Config } from '~/src/geosol/Model';

import * as Con from './Constraint';
import { PieMenuProps } from '~/src/ui/PieMenu';
import { Index , c2i } from './Index';

export type Msg
	= MsgSetPts
	| MsgSetDone
	| MsgSetVal
	| MsgUnDo
	| MsgTogWise
	| MsgSplitEdge
	| MsgSelectEdge
	| MsgSelectNode
	| MsgClearSelection
	| MsgRoundCorner
	| MsgOpenPie
	| MsgClosePie
	| MsgOutage
	;


export interface MsgSetPts {
	type : 'MsgSetPts';
	points : Index< P2 >;
}

export const msgSetPts = ( points : Index< P2 > ) : MsgSetPts =>
	( { type : 'MsgSetPts' , points } );

export const cfg2msg = ( config : Config< number , V2 > ) : MsgSetPts =>
	msgSetPts( c2i( config ) );


export interface MsgSetDone {
	type : 'MsgSetDone';
	done : boolean;
}

export const msgSetDone = ( done : boolean ) : MsgSetDone =>
	( { type : 'MsgSetDone' , done } );


export interface MsgSetLen {
	type : 'MsgSetLen';
	lenid : number;
	value : number;
}

export const msgSetLen = ( lenid : number , value : number ) : MsgSetLen =>
	( { type : 'MsgSetLen' , lenid , value } );


export interface MsgSetAng {
	type : 'MsgSetAng';
	angid : number;
	value : number;
}

export const msgSetAng = ( angid : number , value : number ) : MsgSetAng =>
	( { type : 'MsgSetAng' , angid , value } );


export interface MsgSetVal {
	type : 'MsgSetVal';
	valid : number;
	value : number;
}

export const msgSetVal = ( valid : number , value : number ) : MsgSetVal =>
	( { type : 'MsgSetVal' , valid , value } );


export interface MsgUnDo {
	type : 'MsgUnDo';
}

export const msgUnDo : MsgUnDo = { type : 'MsgUnDo' };


export interface MsgTogWise {
	type : 'MsgTogWise',
	wiseid : number,
}

export const msgTogWise = ( wiseid : number ) : MsgTogWise =>
	( { type : 'MsgTogWise' , wiseid } );


export type Side = 'Left' | 'Right';

export const isLeft = ( side : Side ) : boolean =>
	side === 'Left';

export const isRight = ( side : Side ) : boolean =>
	side === 'Right';


export interface MsgSplitEdge {
	type : 'MsgSplitEdge';
	frameid : number;
	chunkid : number;
	splitOn : Side;
	length : number;
}

export const msgSplitEdge = ( frameid : number , chunkid : number , splitOn : Side , length : number ) : MsgSplitEdge =>
	( { type : 'MsgSplitEdge' , frameid , chunkid , splitOn , length } );

export interface MsgSelectEdge {
	type : 'MsgSelectEdge';
	frameid : number;
	chunkid : number;
	singular : boolean;
}

export const msgSelectEdge = ( frameid : number , chunkid : number , singular : boolean ) : MsgSelectEdge =>
	( { type : 'MsgSelectEdge' , frameid , chunkid , singular } );

export interface MsgClearSelection {
	type : 'MsgClearSelection';
}

export const msgClearSelection : MsgClearSelection =
	( { type : 'MsgClearSelection' } );


export interface MsgSelectNode {
	type : 'MsgSelectNode';
	frameid : number;
	nodeid : number;
	singular : boolean;
}

export const msgSelectNode = ( frameid : number , nodeid : number , singular : boolean ) : MsgSelectNode =>
	( { type : 'MsgSelectNode' , frameid , nodeid , singular } );


export interface MsgRoundCorner {
	type : 'MsgRoundCorner';
	frameid : number;
	nodeid : number;
	radius : number;
}

export const msgRoundCorner = ( frameid : number , nodeid : number , radius : number ) : MsgRoundCorner =>
	( { type : 'MsgRoundCorner' , frameid , nodeid , radius } );


export interface MsgOpenPie {
	type : 'MsgOpenPie';
	menu : PieMenuProps;
}

export const msgOpenPie = ( menu : PieMenuProps ) : MsgOpenPie =>
	( { type : 'MsgOpenPie' , menu } );

export interface MsgClosePie {
	type : 'MsgClosePie';
}

export const msgClosePie : MsgClosePie = ( { type : 'MsgClosePie' } );


export interface MsgOutage {
	type : 'MsgOutage';
	frameid : number;
	first : number;
	final : number;
	value : number;
	side : Con.Side;
	node : Con.Node;
}

export const msgOutage = ( frameid : number , first : number , final : number , value : number , side : Con.Side , node : Con.Node ) : MsgOutage =>
	( { type : 'MsgOutage' , frameid , first , final , value , side , node } );
