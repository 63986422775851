import { pipe } from 'fp-ts/function';
import * as E from 'fp-ts/Either';
import { Either } from 'fp-ts/Either';

import { Loose } from './Utils';
import { WhiskrConfig , HandleConfig } from './Config';
import Extent , * as Ext from './Ruler/Extent';
import Measure , * as Msr from './Ruler/Measure';
import Reading from './Ruler/Reading';
import Angle from './Ruler/Angle';
import Length from './Ruler/Length';

/**
 * Represents a ruler that is measuring some subject and potentially has a
 * reading it wants to display.
 */
export interface Ruler< N , RX = {} , LX = {} , M = Measure< N > > extends Loose< HandleConfig< RX > >{
	subject?: Extent< N >;
	measure : M
	spacing?: number;
	towards?: N;
	reading?: Reading< LX >;
	whisker?: Loose< WhiskrConfig >;
}

export default Ruler;

/**
 * Used to convert point references into actual points. If it fails to find the
 * point for a specific reference it'll return the reference as the left / error
 * value.
 */
export const reify = < N , V >( cloud : Map< N , V > ) => < RX , LX >( { subject , measure , towards , ...rest } : Ruler< N , RX , LX , Measure< N > > ) : Either< N , Ruler< V , RX , LX , Measure< V > > > => pipe( E.Do
	, E.bind( 'tow' , () => {
		if ( !towards ) return E.right( undefined );
		const tow : undefined | V = cloud.get( towards );
		return !tow ? E.left( towards ) : E.right( tow );
	} )
	, E.bind( 'msr' , () => Msr.reify( cloud )( measure ) )
	, E.bind( 'sub' , () => !subject ? E.right( undefined ) : Ext.reify( cloud )( subject ) )
	, E.map( ( { tow , msr : measure , sub } ) => (
		{ measure
			, ...( towards ? { towards : tow } : {} )
			, ...( subject ? { subject : sub } : {} )
			, ...rest
		}
	) )
);

/**
 * Determines if a ruler is measuring an angle.
 */
export const isAngle = < N , RX , LX >( rlr : Ruler< N , RX , LX , Measure< N > > ) : rlr is Ruler< N , RX , LX , Angle< N > > =>
	Msr.isAngle( rlr.measure );

/**
 * Determines if a ruler is measuring a length.
 */
export const isLength = < N , RX , LX >( rlr : Ruler< N , RX , LX , Measure< N > > ) : rlr is Ruler< N , RX , LX , Length< N > > =>
	Msr.isLength( rlr.measure );

