import { P2 } from '~/src/geometry/point';
import { sub , norm } from '~/src/geometry/vector';
import { Mod } from '~/src/base/Function';

/**
 * Makes sure that X does not extend Y.
 */
export type NoEx< X , Y > = X extends Y ? never : X;

/**
 * Control point 0.
 */
export interface C0< T > {
	c0 : T;
}

export const c0 = < T >( f : Mod< T > ) => ( c0 : C0< T > ) : C0< T > => {
	const n0 : T = f( c0.c0 );
	return c0.c0 === n0 ? c0 : { c0 : n0 };
};

/**
 * Control point 1.
 */
export interface C1< T > {
	c1 : T;
}

export const c1 = < T >( f : Mod< T > ) => ( c1 : C1< T > ) : C1< T > => {
	const n1 : T = f( c1.c1 );
	return c1.c1 === n1 ? c1 : { c1 : n1 };
};

/**
 * All of the Cs in one place.
 */
export interface CS< T > extends C0< T > , C1< T > {
}

/**
 * Check if a number is near 0.
 */
export const nz = ( n : number ) : boolean => Math.abs( n ) <= 0.000001;

/**
 * Print a 2D point as a string in the format of "x,y".
 */
export const pp = ( [ x , y ] : P2 ) : string => `${x},${y}`;

/**
 * Calculate the radius of a circle through 3 points (if it exists).
 */
export const cr = ( p0 : P2 , p1 : P2 , p2 : P2 ) : null | number => {
	const a : number = norm( sub(p1)(p0) );
	const b : number = norm( sub(p2)(p1) );
	const c : number = norm( sub(p0)(p2) );
	const s : number = ( a + b + c ) / 2;

	const A : number = Math.sqrt(s * ( s - a ) * ( s - b ) * ( s - c ) );

	return nz(a) ? null : ( a * b * c ) / ( 4 * A );
};

/**
 * Check if the 3 ordered points are forming a clockwise or a counter-clockwise
 * relationship (or if they are colinear, or cooincident which is represented by
 * null)
 */
export const cwise = ( [ x1 , y1 ] : P2 , [ x2 , y2 ] : P2 , [ x3 , y3 ] : P2 ) : null | 0 | 1 => {
	const cprod : number = ( x2 - x1 ) * ( y3 - y1 ) - ( y2 - y1 ) * ( x3 - x1 );
	return nz( cprod ) ? null : cprod > 0 ? 1 : 0;
};

/**
 * Given the circle radius and 3 ordered points that lie on the circle, we
 * determine if the circle segment between point a and c is large or small.
 */
export const large = ( r : number , a : P2 , b : P2 , c : P2 ) : boolean =>
	( norm( sub(b)(a) ) + norm( sub(c)(b) ) ) >= 2 * r * Math.sqrt(2);
