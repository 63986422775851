import * as E from 'fp-ts/Either';
import { Either } from 'fp-ts/Either';

import { P2 } from '~/src/geometry/point';
import { NonEmpty , refineNonEmpty } from '~/src/base/Array/NonEmpty';
import { get } from '~/src/base/Optic';

import { pp } from './Chunk/Shared';
import Chunk , * as Chk from './Chunk';
import * as Lin from './Chunk/Linear';
import * as Rad from './Chunk/Radial';
import * as Bez from './Chunk/Bezier';

/**
 * A single piece of a more complex shape. The piece can contain some metadata
 * that will be provided when user interacts with the piece by some JS event.
 */
export type Piece< N , X = {} >
	= Chk.ChunkX< N , X >;

/**
 * A basic shape type without any extra metadata.
 */
export type Basic< N >
	= NonEmpty< [ N , Chunk< N > ] >;

/**
 * A complex closed shape made out of multiple pieces.
 */
export type Shape< N , X = {} >
	= NonEmpty< [ N , Piece< N , X > ] >;

export default Shape;


/**
 * The fmap operation over the A parameter.
 */
export const fmap = < A , B >( f : ( p : A ) => B ) => < X >( shape : Shape< A , X > ) : Shape< B , X > =>
	shape.map( ( [ curr , chnk ] ) => [ f( curr ) , Chk.fmap( f )( chnk ) ] ) as Shape< B , X >;

/**
 * Used to convert point references into actual points. If it fails to find the
 * point for a specific reference it'll return the reference as the left / error
 * value.
 */
export const reify = < N , V >( cloud : Map< N , V > ) => < X >( shape : Shape< N , X > ) : Either< N , Shape< V , X > > =>
	E.traverseArray< N , [ N , Piece< N , X > ] , [ V , Piece< V , X > ] >( ( [ node , chunk ] : [ N , Piece< N , X > ] ) => {
		const point : undefined | V = cloud.get( node );
		const value : Either< N , [ V , Piece< V , X > ] > = !point
			? E.left( node )
			: E.map( piece => [ point , piece ] as [ V , Piece< V , X > ] )( Chk.reify( cloud )( chunk ) );

		return value;
	} )( shape ) as Either< N , Shape< V , X > >;

/**
 * Generates the absolute path string to be used with the SVG's path element.
 */
export const absPath = ( shape : Shape< P2 , unknown > ) : string =>
	shape.reduce( ( path , [ curr , chnk ] , i ) => {
		const next : P2 = shape?.[ i + 1 ]?.[0] ?? shape[0][0];

		if ( Chk.isBezier( chnk ) )
			return path + ' ' + Bez.relPath( chnk , next );

		if ( Chk.isRadial( chnk ) )
			return path + ' ' + Rad.relPath( curr , chnk , next );

		return path + ' ' + Lin.relPath( next );
	} , `M ${ pp( shape[0][0] ) }` ) + ' Z';


export interface ReLinear< N > {
	type : 'ReLinear';
	first : N;
	nodes : N[];
	final : N;
}

export const reDraw = < N >( f : ( segs : [ N , Chunk< N > ][] ) => ( n : N ) => void ) => ( first : N , final : N ) => ( shape : Basic< N > ) : Basic< N > => {
	const len : number = shape.length;

	let fstIx : number | null = null;
	let finIx : number | null = null;

	const looped : Basic< N > = shape.concat( shape ) as Basic< N >;

	const segments : [ N , Chunk< N > ][] = [];

	for ( const [ ix , [ n , c ] ] of looped.entries() ) {
		if ( fstIx !== null && finIx !== null )
			break;

		const ns : N[] = [ n ].concat( get( Chk.chunkN )( c ) );

		if ( fstIx === null && ns.includes( first ) ) {
			fstIx = ix;
			takeUntil( first )( ns ).forEach( f( segments ) );
		}

		if ( fstIx !== null && finIx === null && ns.includes( final ) ) {
			finIx = ix;

			if ( final === n )
				segments.push( [ n , c ] );
			else
				dropUntil( final )( ns ).forEach( n => segments.push( [ n , {} ] ) );
		}
	}

	if ( fstIx !== null && finIx !== null ) {
		const diff : number = finIx - fstIx;
		const left : number = len - diff;

		looped
			.slice( finIx + 1 , finIx + left )
			.forEach( c => segments.push( c ) );

		if ( refineNonEmpty( segments ) )
			return segments as Basic< N >;
	}

	return shape;
};

export const reLinear = < N >( first : N , nodes : N[] , final : N ) => ( shape : Basic< N > ) : Basic< N > => {
	const shp = reDraw< N >( segs => n => {
		segs.push( [ n , {} ] );
		if ( first !== n ) return;
		segs.push( ...nodes.map( n => [ n , {} ] as [ N , Chunk< N > ] ) );
	} )( first , final )( shape );

	return shp;
};

export const reBezier = < N >( first : N , c0 : N , c1 : N , final : N ) => ( shape : Basic< N > ) : Basic< N > =>
	reDraw< N >( segs => n =>
		segs.push( [ n , n !== first ? {} : { c0 , c1 } ] )
	)( first , final )( shape );

export const reRadial = < N >( first : N , c0 : N , final : N ) => ( shape : Basic< N > ) : Basic< N > =>
	reDraw< N >( segs => n =>
		segs.push( [ n , n !== first ? {} : { c0 } ] )
	)( first , final )( shape );

export const takeUntil = < T >( el : T ) => ( els : T[] ) : T[] => {
	const tks : T[] = [];

	for ( const e of els ) {
		tks.push( e );

		if ( e === el )
			break;
	}

	return tks;
};

export const dropUntil = < T >( el : T ) => ( els : T[] ) : T[] => {
	const tks : T[] = [];
	let found : boolean = false;

	for ( const e of els ) {
		if ( !found && e === el )
			found = true;

		if ( !found )
			continue;

		tks.push( e );
	}

	return tks;
};

export const neighbours = < N >( shape : Basic< N > ) => ( n : N ) : [ N , N , N ] | null => {
	const nodes : N[] = [];

	shape.forEach( ( [ n , c ] ) => {
		nodes.push( n );
		get( Chk.chunkN )( c ).forEach( n => nodes.push( n ) );
	} );

	if ( !refineNonEmpty( nodes ) )
		return null;

	const fst : N = nodes[ 0 ];
	const lst : N = nodes[ nodes.length - 1 ] ?? fst;

	for ( const [ ix , node ] of nodes.entries() ) {
		if ( node !== n ) continue;

		const prev : N = nodes[ ix - 1 ] ?? lst;
		const next : N = nodes[ ix + 1 ] ?? fst;

		return [ prev , n , next ];
	}

	return null;
};
