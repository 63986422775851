import * as E from 'fp-ts/Either';
import { Either } from 'fp-ts/Either';

import { Optic , dot } from '~/src/base/Optic';

import Shape , * as Shp from './Shape';
import { Loose } from './Utils';
import { HandleConfig } from './Config';

/**
 * A frame is just a wrapper around the Shape used to carry the configuration
 * metadata.
 */
export interface Frame< N , CX = {} , FX = {} > extends Loose< HandleConfig< FX > > {
	shape : Shape< N , Loose< HandleConfig< CX > > >;
}

export default Frame;

export const shape
	= < N , CX , FX >()
	: Optic< Frame< N , CX , FX > , Shape< N , Loose< HandleConfig< CX > > > > =>
			dot( 'shape' );

/**
 * Used to convert point references into actual points. If it fails to find the
 * point for a specific reference it'll return the reference as the left / error
 * value.
 */
export const reify = < N , V >( cloud : Map< N , V > ) => < CX , FX >( { shape , ...rest } : Frame< N , CX , FX > ) : Either< N , Frame< V , CX , FX > > =>
	E.map( shape => ( { shape , ...rest } ) as Frame< V , CX , FX > )( Shp.reify( cloud )( shape ) );
