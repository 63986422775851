import Extent , * as Ext from './Extent';

/**
 * A simple length measurement. It contains the first and final point of a
 * measurement.
 */
export interface Length< N > extends Extent< N > {
}

export default Length;


/**
 * Used to convert point references into actual points. If it fails to find the
 * point for a specific reference it'll return the reference as the left / error
 * value.
 */
export const reify = Ext.reify;
