import { useMemo , useEffect , useReducer } from 'react';

import { comp } from '~/src/base/Function';
import { set , opt , index } from '~/src/base/Optic';
import { Requestor } from '~/src/api/Requestor';

import { V2 , about0 } from '~/src/geometry/vector';
import { P2 } from '~/src/geometry/point';
import * as Pnt from '~/src/geometry/point';
import { Three } from '~/src/base/Array/Three';
import { Message , SketchView } from '~/src/sketch/Sketch';
import * as Sch from '~/src/sketch/Sketch';
import * as Shp from '~/src/sketch/Model/Shape';
import { Basic , reLinear , reRadial , neighbours } from '~/src/sketch/Model/Shape';
import { preset } from '~/src/sketch/Model/Preset';
import { Chunk , isRadial , isBezier } from '~/src/sketch/Model/Chunk';
import { solveSimple2D } from '~/src/geosol/Api';
import PieMenu , { PieMenuProps } from '~/src/ui/PieMenu';

import inits from './Inits';
import { Index , push } from './Index';
import { Design , N , C , F , R , L , pnts , pics , cons , cval , cwse } from './Design';
import { Sketch , design2sketch , design2simple } from './Design';
import { Con , len , ang , rnd , togWse } from './Constraint';
import * as Cons from './Constraint';
import { Sels , State , mkstate , mksels , rCatSels , prev , curr , done , menu } from './State';
import * as Ms from './Message';
import { Msg , Side , isLeft } from './Message';


export const reducer = ( state : State , msg : Msg ) : State => {
	const history = state.prev;
	const noHistory = history.length === 0;
	const settled = state.done || noHistory;

	switch ( msg.type ) {
	case 'MsgSetPts' : {
		return comp( set( done )( true ) , set( comp( curr , pnts ) )( msg.points ) )( state );
	}

	case 'MsgSetDone' : {
		return set( done )( msg.done )( state );
	}

	case 'MsgSetVal' : {
		if ( !settled )
			return state;

		const ncurr : Design = set( cval( msg.valid ) )( msg.value )( state.curr );

		if ( ncurr === state.curr )
			return state;

		const newState = comp( set( done )( false )
			, set( curr )( ncurr )
			, prev( ps => [ ...ps , state.curr ] )
		)( state );

		return newState;
	}

	case 'MsgUnDo' : {
		if ( noHistory || !state.done )
			return state;

		const newState = comp ( curr( cd => state.prev?.[ history.length - 1 ] ?? cd )
			, prev( ps => ps.slice( 0 , -1 ) )
		)( state );

		return newState;
	}

	case 'MsgTogWise' : {
		if ( !settled )
			return state;

		const ncurr : Design = cwse( msg.wiseid )( opt( togWse ) )( state.curr );

		if ( ncurr === state.curr )
			return state;

		const newState = comp( set( done )( false )
			, set( curr )( ncurr )
			, prev( ps => [ ...ps , state.curr ] )
		)( state );

		return newState;
	}

	case 'MsgSplitEdge' : {
		if ( !settled )
			return state;

		const pict : Basic< number > | undefined = state.curr.pics?.[ msg.frameid ];
		const edge : [ number , Chunk< number > ] | undefined = pict?.[ msg.chunkid ];
		const next : [ number , Chunk< number > ] | undefined = pict?.[ msg.chunkid + 1 ] ?? pict?.[0];

		if ( !pict || !edge || !next || isBezier( edge[1] ) || isRadial( edge[1] ) )
			return state;

		const npic : Basic< number > = reLinear< number >( edge[0] , [ state.curr.pnts.next ] , next[0] )( pict );

		const pprev : number = edge[0];
		const pcurr : number = state.curr.pnts.next;
		const pnext : number = pict[ msg.chunkid + 1 ]?.[0] ?? pict[0][0];

		const conLen : Con = isLeft( msg.splitOn )
			? len( pprev , pcurr )( msg.length )
			: len( pcurr , pnext )( msg.length );
		const conAng : Con = ang( pprev , pcurr , pnext )( 180 )( 'CCW' );

		const ncons : Index< Con > = push< Con >( conLen , conAng )( state.curr.cons );

		const newState = comp( set( done )( false )
			, set( comp( curr , pics , index( msg.frameid )< Basic< number > > ) )( npic )
			, set( comp( curr , cons ) )( ncons )
			, prev( ps => [ ...ps , state.curr ] )
		)( state );

		return newState;
	}

	case 'MsgOutage' : {
		if ( !settled )
			return state;

		const pict : Basic< number > | undefined = state.curr.pics?.[ msg.frameid ];

		if ( !pict )
			return state;

		const cix : number = state.curr.pnts.next;
		const tgtn : number = msg.node === 'First' ? msg.first : msg.final;
		const npic : Basic< number > = Shp.fmap( ( n : number ) => n === tgtn ? cix : n )( pict );

		const con : Con = Cons.out( msg.first , msg.final , msg.value , msg.node , msg.side );
		const ncons : Index< Con > = push< Con >( con )( state.curr.cons );

		const newState = comp( set( done )( false )
			, set( comp( curr , pics , index( msg.frameid )< Basic< number > > ) )( npic )
			, set( comp( curr , cons ) )( ncons )
			, prev( ps => [ ...ps , state.curr ] )
		)( state );

		return newState;
	}

	case 'MsgSelectEdge' : {
		if ( !settled )
			return state;

		const singl : Sels = mksels( 'Edge' , msg.frameid , msg.chunkid );

		if ( !state?.sels )
			return { ...state , sels : singl };

		if ( msg.singular )
			return { ...state , sels : singl };

		const nsels : Sels = rCatSels( state.sels )( singl );

		return { ...state , sels : nsels };
	}

	case 'MsgSelectNode' : {
		if ( !settled )
			return state;

		const singl : Sels = mksels( 'Node' , msg.frameid , msg.nodeid );

		if ( !state?.sels )
			return { ...state , sels : singl };

		if ( msg.singular )
			return { ...state , sels : singl };

		const nsels : Sels = rCatSels( state.sels )( singl );

		return { ...state , sels : nsels };
	}

	case 'MsgClearSelection' : {
		if ( !settled || !state?.sels )
			return state;

		const { sels , ...rest } = state;

		return rest;
	}

	case 'MsgRoundCorner' : {
		if ( !settled )
			return state;

		const pic : Basic< number > | undefined = state.curr.pics[ msg.frameid ];

		if ( pic === undefined )
			return state;

		const nghb : [ number , number , number ] | null = neighbours< number >( pic )( msg.nodeid );

		if ( nghb === null )
			return state;

		const con : Con = rnd( nghb[0] , nghb[1] , nghb[2] )( msg.radius );
		const cix : number = state.curr.pnts.next;
		const abc : Three< number > = [ cix + 3 , cix + 5 , cix + 4 ];

		const npic : Basic< number > = reRadial< number >( abc[0] , abc[1] , abc[2] )( reLinear< number >( nghb[0] , abc , nghb[2] )( pic ) );
		const ncons : Index< Con > = push< Con >( con )( state.curr.cons );

		const newState = comp( set( done )( false )
			, set( comp( curr , pics , index( msg.frameid )< Basic< number > > ) )( npic )
			, set( comp( curr , cons ) )( ncons )
			, prev( ps => [ ...ps , state.curr ] )
		)( state );
		return newState;
	}

	case 'MsgOpenPie' : {
		return set( menu )( msg.menu )( state );
	}

	case 'MsgClosePie' : {
		const { menu : _ , ...rest } = state;
		return rest;
	}

	}
};


export type Handlers = Sch.Handlers< number , number , number , N , C , F , R , L >;


export interface GUIProps {
	req : Requestor;
}

export const GUI = ( { req } : GUIProps ) => {
	const [ state , dispatch ] = useReducer( reducer , mkstate( inits.rectangle ) );

	const design : undefined | Design = useMemo( () =>
		state.done ? state.curr : state.prev?.[ state.prev.length - 1 ]
	, [ state ] );

	const sketch : undefined | Sketch = useMemo( () =>
		design ? design2sketch( design ) : undefined
	, [ design ] );

	useEffect( () => {
		if ( !state.done )
			solveSimple2D( req )( design2simple( state.curr ) )
				.then( cfgs => {

					if ( cfgs?.[0] ) {
						dispatch( Ms.cfg2msg( cfgs[ 0 ] ) );
					} else {
						dispatch( Ms.msgSetDone( true ) );
						dispatch( Ms.msgUnDo );
					}
				} )
				.catch( console.error );
	} , [ req , state.done , state.curr ] );

	const rounder = useMemo( () => ( fid ?: number , pid ?: number ) => {
		if ( ( fid === undefined || pid === undefined ) && state?.sels?.stype !== 'Node' )
			return;

		const frame : number | undefined = fid ?? state?.sels?.frame;
		const point : number | undefined = pid ?? state?.sels?.items?.[0];

		if ( frame === undefined || point === undefined ) return;

		const prompt : null | string = window.prompt( 'Radius:' );

		if ( prompt === null || prompt.trim() === '' ) return;

		const value : number = parseFloat( prompt.trim() );

		dispatch( Ms.msgRoundCorner( frame , point , value ) );

	} , [ state?.sels , dispatch ] );

	const splitter = useMemo( () => ( fid ?: number , eid ?: number ) => {
		if ( ( fid === undefined || eid === undefined ) && state?.sels?.stype !== 'Edge' )
			return;

		const frame : number | undefined = fid ?? state?.sels?.frame;
		const chunk : number | undefined = eid ?? state?.sels?.items?.[0];

		if ( frame === undefined || chunk === undefined ) return;

		const prompt : null | string = window.prompt( 'Distance:' );

		if ( prompt === null || prompt.trim() === '' ) return;

		const value : number = parseFloat( prompt.trim() );

		if ( isNaN( value ) ) return;

		const left : boolean = window.confirm( 'From the left? (if not, then from the right)' );
		const side : Side = left ? 'Left' : 'Right';

		dispatch( Ms.msgSplitEdge( frame , chunk , side , value ) );
	} , [ state?.sels , dispatch ] );

	const offsetter = useMemo( () => ( node : Cons.Node , side : Cons.Side ) => ( fid ?: number , msg ?: C ) => {
		if ( fid === undefined || msg === undefined )
			return;

		const frame : number | undefined = fid ?? state?.sels?.frame;
		const chunk : number | undefined = msg?.cid ?? state?.sels?.items?.[0];

		if ( frame === undefined || chunk === undefined ) return;

		const prompt : null | string = window.prompt( 'Offset:' );

		if ( prompt === null || prompt.trim() === '' ) return;

		const value : number = parseFloat( prompt.trim() );

		if ( isNaN( value ) ) return;

		dispatch( Ms.msgOutage( frame , msg.fst , msg.fin , value , side , node ) );
	} , [ state?.sels , dispatch ] );

	const pntHndlr = useMemo( () => ( msg : undefined | Message< number , N > ) => ( evt : any ) => {
		if ( !msg ) return;
		evt?.stopPropagation();

		dispatch( Ms.msgSelectNode( 0 , msg.address , !( evt?.shiftKey ?? false ) ) );
	} , [ dispatch ] );

	const pmenuHndlr = useMemo( () => ( msg : undefined | Message< number , N > ) => ( evt : any ) => {
		if ( !msg ) return;
		evt?.preventDefault();
		evt?.stopPropagation();

		const x : number | undefined = evt?.clientX;
		const y : number | undefined = evt?.clientY;
		const fid : number = 0;
		const nid : number = msg.address;

		if ( !x || !y ) return;

		dispatch( Ms.msgOpenPie( nodePie( dispatch )( rounder )( x , y , fid , nid ) ) );
	} , [ dispatch , rounder ] );

	const emenuHndlr = useMemo( () => ( msg : undefined | Message< [ number , number ] , C > ) => ( evt : any ) => {
		if ( !msg ) return;
		evt?.preventDefault();
		evt?.stopPropagation();

		const x : number | undefined = evt?.clientX;
		const y : number | undefined = evt?.clientY;
		const fid : number = msg.address[0];
		// const nid : number = msg.address[1];

		if ( !x || !y || msg?.message === undefined ) return;

		dispatch( Ms.msgOpenPie( edgePie( dispatch )( splitter )( offsetter )( x , y , fid , msg.message ) ) );
	} , [ dispatch , splitter ] );

	const rlrHndlr = useMemo( () => ( msg : undefined | Message< number , R > ) => ( evt : any ) => {
		if ( !msg || !msg.message ) return;

		const shift : boolean = evt?.shiftKey ?? false;

		if ( shift )
			return dispatch( Ms.msgTogWise( msg.message.cid ) );

		const prompt : null | string = window.prompt( 'Value:' );

		if ( !prompt || prompt.trim() === '' ) return;

		const value : number = parseFloat( prompt.trim() );

		if ( Number.isNaN( value ) ) return;

		dispatch( Ms.msgSetVal( msg.message.cid , value ) );
	} , [ dispatch ] );

	const selHndlr = useMemo( () => ( msg : undefined | Message< [ number , number ] , C > ) => ( evt : any ) => {
		if ( !msg ) return;
		evt?.stopPropagation();
		dispatch( Ms.msgSelectEdge( msg.address[0] , msg.address[1] , !( evt?.shiftKey ?? false ) ) );
	} , [ dispatch ] );

	const hndlrs : Handlers = useMemo( () => ({
		ruler : {
			ruler : {
				onClick : rlrHndlr
			},
			label : {
				onClick : rlrHndlr
			},
		},
		chunk : {
			onClick : selHndlr,
			onContextMenu : emenuHndlr
		},
		point : {
			onClick : pntHndlr,
			onContextMenu : pmenuHndlr
		}
	}) , [ rlrHndlr , selHndlr , pntHndlr , emenuHndlr , pmenuHndlr ] );



	const genClick = useMemo( () => () =>
		dispatch( Ms.msgClearSelection )
	, [ dispatch ] );

	useEffect( () => {
		const keydown = ( evt : KeyboardEvent ) => {
			if ( evt.key === 'z' && evt.ctrlKey )
				dispatch( Ms.msgUnDo );
			if ( evt.key === 's' )
				splitter();
			if ( evt.key === 'r' )
				rounder();
		};

		document.addEventListener( 'keydown' , keydown );

		return () =>
			document.removeEventListener( 'keydown' , keydown );
	} , [ dispatch , splitter , rounder ] );

	const fixer : ( p : P2 ) => P2 = useMemo( () => fix2x( 0 , 1 , state.curr.pnts ) , [ state.curr.pnts ] );

	return !sketch ? null :
		<div style={{ width : '100%' , height : '100%' }} onClick={ genClick }>
			<SketchView
				width='100%'
				height='100%'
				fntsze={ 10 }
				value={ sketch }
				caster={ fixer }
				preset={ preset }
				hndlrs={ hndlrs }
			/>

			{ !state?.menu ? null : <PieMenu {...state.menu} /> }
		</div>
	;
};

export const nodePie = ( dispatch : ( msg : Msg ) => void ) => ( rounder : ( fid : number , nid : number ) => void ) => ( x : number , y : number , fid : number , nid : number ) : PieMenuProps =>
	(
		{ x , y
			, onClose : () => {}
			, entries : [
				{ icon : 'R'
					, title : 'Round corner.'
					, onSelect: () => {
						rounder( fid , nid );
						dispatch( Ms.msgClosePie );
					}
				}
			]
		}
	);

export const edgePie = ( dispatch : ( msg : Msg ) => void ) =>
	( splitter : ( fid : number , eid : number ) => void ) =>
		( offsetter : ( node : Cons.Node , side : Cons.Side ) => ( fid : number , msg : C ) => void ) => ( x : number , y : number , fid : number , meta : C ) : PieMenuProps =>
			(
				{ x , y
					, onClose : () => {}
					, entries : [
						{ icon : 'S'
							, title : 'Split edge.'
							, onSelect : () => {
								splitter( fid , meta.cid );
								dispatch( Ms.msgClosePie );
							}
						},
						{ icon : 'TL', title : 'Top Left Offset.'
							, onSelect : () => {
								offsetter( 'Final' , 'Left' )( fid , meta );
								dispatch( Ms.msgClosePie );
							}
						},
						{ icon : 'TR', title : 'Top Right Offset.'
							, onSelect : () => {
								offsetter( 'Final' , 'Right' )( fid , meta );
								dispatch( Ms.msgClosePie );
							}
						},
						{ icon : 'BL', title : 'Bottom Left Offset.'
							, onSelect : () => {
								offsetter( 'First' , 'Left' )( fid , meta );
								dispatch( Ms.msgClosePie );
							}
						},
						{ icon : 'BR', title : 'Bottom Right Offset.'
							, onSelect : () => {
								offsetter( 'First' , 'Right' )( fid , meta );
								dispatch( Ms.msgClosePie );
							}
						},
					]
				}
			);

export const fix2x = ( z : number , o : number , pts : Index< P2 > ) : ( p : P2 ) => P2 => {
	const zp : P2 | undefined = pts.data.get( z );
	const op : P2 | undefined = pts.data.get( o );

	if ( zp === undefined || op === undefined )
		return p => p;

	const zo : V2 = Pnt.sub(op)(zp);
	const ro : ( v : V2 ) => V2 = about0( -Math.atan2( zo[1] , zo[0] ) );

	return p => Pnt.p( ro( Pnt.subV( p )( Pnt.unP( zp as P2 ) ) ) );
};
