import * as React from 'react';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

import { ViewProps } from '~/src/view/types';
import { Requestor } from '~/src/api/Requestor';
import { postPriceGrid } from '~/src/api/Pricing';

import KindEdit from '~/src/edit/Kind';
import PlanEdit from '~/src/edit/Plan';
import { Plan , PathKey , showPath , validPlan } from '~/src/model/Tableau';
import { GridId , PriceGrid , PriceItem , Kind } from '~/src/model/Pricing';
import { PriceGridProps , PriceGridEdit } from '~/src/edit/PriceGrid';
import { getPriceItems } from '~/src/api/Pricing';

export const PriceGridListPage = ( { value , edit } : ViewProps<Record<GridId, PriceGrid>> & { edit : ( gid : GridId ) => () => void } ) => {
	return (
		<>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Grid Name</TableCell>
							<TableCell>Edit</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{ Object.entries(value)
							.map( ([gid , g] : [string , PriceGrid] ) =>
								<TableRow key={gid}>
									<TableCell component='th' scope='row'>
										{g.Name}
									</TableCell>

									<TableCell>
										<Button variant='text' onClick={ edit( gid ) }>Edit</Button>
									</TableCell>
								</TableRow>
							)
						}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export const PriceGridEditPage = ( { req , grid } : { req : Requestor , grid : PriceGrid } ) => {
	const [ gridProps , setGridProps ] = React.useState<PriceGridProps>( { grid : grid , imap : {} } );

	React.useEffect( () => {
		const loadPriceItems = async () => {
			console.log( 'Running the loadPriceItems', grid.Id );

			try {
				const items = await getPriceItems( req )( grid.Id );
				const nitms = Object
					.values( items )
					.reduce( ( rec , itm ) => { rec[ showPath( itm.Path ) ] = itm; return rec; } , {} as Record<PathKey, PriceItem> );

				setGridProps( { grid : grid , imap : nitms } );
			} catch ( err ) {
				console.error( err );
			}
		};

		loadPriceItems();
	} , [ req , grid ] );

	return <PriceGridEdit req={req} value={ gridProps } update={ f => setGridProps( f( gridProps ) ) } />;
};

export const PriceGridMakePage = ( { req , aok } : { req : Requestor , aok : () => void } ) => {
	const [ doin , setDoin ] = React.useState<boolean>(false);
	const [ errs , setErrs ] = React.useState<string[]>([]);
	const [ name , setName ] = React.useState<string>('');
	const [ plan , setPlan ] = React.useState<Plan>([]);
	const [ kind , setKind ] = React.useState<Kind>({ kind : 'Price' });
	const [ many , setMany ] = React.useState<boolean>(false);

	const alert =
		<Alert severity='error'>
			<AlertTitle>Errors</AlertTitle>
			<ul>
				{ errs.map( err => <li key={err}>{err}</li> ) }
			</ul>
		</Alert>;

	const handleCreate = () => {
		const hasSpace = (s : string) : boolean => /\s/.test(s);

		const errors : string[] = [];
		const newGrid : PriceGrid = {
			Id : '',
			Name : name,
			Plan : plan,
			Kind : kind,
			Many : many,
		};

		setDoin(true);

		if (name === '' || hasSpace(name) ) {
			errors.push( 'Name is invalid.' );
		}

		if (!validPlan( plan )) {
			errors.push( 'Plan is invalid.' );
		}

		if (errors.length) {
			setErrs( errors );
			setDoin( false );
			return;
		}

		postPriceGrid( req )( newGrid )
			.then( () => aok() )
			.catch( err => {
				console.log( err );
				setErrs( [ 'Unable to create new PriceGrid for some reason.' ] );
				setDoin( false );
			} );
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<Stack spacing={2}>
					{ !errs.length ? null : alert }

					<TextField required label='Grid Name' variant='outlined' onChange={ e => setName(e.target.value) } />

					<Paper sx={{ padding : '1em' }}>
						<PlanEdit value={plan} update={ f => setPlan( f( plan ) ) } />
					</Paper>

					<Button size='large' disabled={doin} variant='outlined' onClick={handleCreate}>Create</Button>
				</Stack>
			</Grid>

			<Grid item xs={6}>
				<FormGroup>
					<FormControlLabel
						control={<Switch checked={many} onChange={ e => setMany( e.target.checked ) } />}
						label="Results in multiple values"
					/>
					<KindEdit value={kind} update={ f => setKind( f( kind ) ) } />
				</FormGroup>
			</Grid>

		</Grid>
	);
};
