import { Key , SVGProps , ReactElement , CSSProperties , DOMAttributes , EventHandler , useMemo } from 'react';

import * as E from 'fp-ts/Either';
import { Either } from 'fp-ts/Either';

import { Optic , dot } from '~/src/base/Optic';
import { V2 }from '~/src/geometry/vector';
import * as Vec from '~/src/geometry/vector';
import { P , P2 , p } from '~/src/geometry/point';
import * as Pnt from '~/src/geometry/point';
import { clss } from '~/src/util/Css';
import { ViewProps } from '~/src/view/types';
import { useSize } from '~/src/hooks/useSize';
import { pipe } from '~/src/base/Function';
// import { AngleConverters , LengthConverters } from '~/src/util/Units';

import Ruler , * as Rlr from './Model/Ruler';
import Shape , * as Shp from './Model/Shape';
import Chunk , * as Chk from './Model/Chunk';
import Frame , * as Frm from './Model/Frame';
import Orient from './Model/Ruler/Orient';
import Reading from './Model/Ruler/Reading';
import { Loose } from './Model/Utils';
import { RulerPreset , SketchPreset , preset } from './Model/Preset';
import { WhiskrConfig , HandleConfig , tightenWhiskr , tightenHandle } from './Model/Config';
import Measure from './Model/Ruler/Measure';
import Angle from './Model/Ruler/Angle';
import Length from './Model/Ruler/Length';

import * as css from './Sketch.module.css';


// Event Interface
export interface Address< A > {
	address : A;
}

export interface Message< A , M > extends Address< A > {
	message ?: M;
}

export const mkMsg = < A, >( address : A ) => < M, >( message ?: M ) : Message< A , M > =>
	( { address , message } );

type DOMA< E > = DOMAttributes< E >;
type OnEvent = `on${ string }`;

export type RegularHandlers< Element > = {
	[ K in keyof DOMA< Element > as K extends OnEvent ? K : never ] ?:
	DOMA< Element >[ K ];
};

export type MessageHandlers< Element , Message = {} > = {
	[ K in keyof DOMA< Element > as K extends OnEvent ? K : never ] ?:
	( DOMA< Element >[ K ] extends ( EventHandler< infer Event > | undefined )
	? ( message ?: Message ) => ( event ?: Event ) => void
	: never
	);
};

export const toRegular = < Message, >( msg ?: Message ) => < Element, >( mhs : MessageHandlers< Element , Message > ) : RegularHandlers< Element > => {
	const rhs : RegularHandlers< Element > = {};

	for ( const key in mhs ) {
		const mh = mhs[ key as keyof MessageHandlers< Element , Message > ];

		if ( mh )
			rhs[ key as keyof RegularHandlers< Element > ] =
				// eslint-disable-next-line  @typescript-eslint/no-explicit-any
				( event : any ) => mh( msg )( event );
	}

	return rhs;
};

export type PointAddr< N > = N;
export type PointHandlers< N , NX = {} >
	= MessageHandlers< SVGCircleElement , Message< PointAddr< N > , NX > >;

export type ChunkAddr< F > = [ F , number ];
export type ChunkHandlers< F , CX = {} >
	= MessageHandlers< SVGPathElement , Message< ChunkAddr< F > , CX > >;

export type ShapeAddr< F > = F;
export type FrameAddr< F > = F;
export type FrameHandlers< F , FX = {} >
	= MessageHandlers< SVGPathElement , Message< FrameAddr< F > , FX > >;

export type RulerAddr< R > = R;
export type ReadingHandlers< R , LX = {} >
	= MessageHandlers< SVGCircleElement , Message< RulerAddr< R > , LX > >;

export interface RulerHandlers< R , RX = {} , LX = {} > {
	ruler : MessageHandlers< SVGPathElement , Message< RulerAddr< R > , RX > >;
	label : ReadingHandlers< R , LX >;
}

export interface Handlers< N , F , R , NX = {} , CX = {} , FX = {} , RX = {} , LX = {} > {
	point ?: PointHandlers< N , NX >;
	chunk ?: ChunkHandlers< F , CX >;
	frame ?: FrameHandlers< F , FX >;
	ruler ?: RulerHandlers< R , RX , LX >;
}

// Drawing Interface

export interface Sketch< N , F , R , V , NX = {} , CX = {} , RX = {} , LX = {} , FX = {} > {
	points : Map< N , Point< V , NX > >;
	frames : Map< F , Frame< N , CX , FX > >;
	rulers : Map< R , Ruler< N , RX , LX > >;
}

export const points
	= < N , F , R , V , NX , CX , RX , LX , FX >()
	: Optic< Sketch< N , F , R , V , NX , CX , RX , LX , FX > , Map< N , Point< V , NX > > > =>
			dot( 'points' );

export const frames
	= < N , F , R , V , NX , CX , RX , LX , FX >()
	: Optic< Sketch< N , F , R , V , NX , CX , RX , LX , FX > , Map< F , Frame< N , CX , FX > > > =>
			dot( 'frames' );

export const rulers
	= < N , F , R , V , NX , CX , RX , LX , FX >()
	: Optic< Sketch< N , F , R , V , NX , CX , RX , LX , FX > , Map< R , Ruler< N , RX , LX > > > =>
			dot( 'rulers' );


export interface Point< V , NX = {} > extends Loose< HandleConfig< NX > > {
	point : P< V >;
}

export const point = < V , NX >() : Optic< Point< V , NX > , P< V > > =>
	dot( 'point' );


// Shape Interface

export interface Piece< N , E > extends Loose< HandleConfig< E > > {
	first : N;
	chunk : Chunk< N >;
	final : N;
}


// Views

export interface ModeProps< M > {
	mode : M;
}

export interface CasterProps< V > {
	caster : ( p : P< V > ) => P2;
}

export interface PresetProps< P > {
	preset : P;
}

export interface HndlrsProps< H > {
	hndlrs?: H;
}

export interface FntSzeProps {
	fntsze : number;
}


export type SketchViewProps< N , F , R , V , NX = {} , CX = {} , RX = {} , LX = {} , FX = {} >
	= ViewProps< Sketch< N , F , R , V , NX , CX , RX , LX , FX > >
	& CasterProps< V >
	& PresetProps< SketchPreset< NX , CX , FX , RX , LX > >
	& SVGProps< SVGSVGElement >
	& HndlrsProps< Handlers< N , F , R , NX , CX , FX , RX , LX > >
	& FntSzeProps
	;

export const SketchView
	= < N extends Key , F extends Key , R extends Key , V , NX , CX , RX , LX , FX >
		( { value : sketch , caster , preset , hndlrs , fntsze , ...props } : SketchViewProps< N , F , R , V , NX , CX , RX , LX , FX > ) => {
		const [ ref , size ] = useSize<SVGSVGElement>();

		const center = useMemo( () => ( p : P2 ) : P2 => {
			return [ p[ 0 ] + size.w / 2 , -p[ 1 ] + size.h / 2 ];
		} , [ size ] );

		const specks : Map< N , P2 > = useMemo( () => {
			const result : Map< N , P2 > = new Map();

			for ( const [ key , val ] of sketch.points )
				result.set( key , caster( val.point ) );

			return result;
		} , [ caster , sketch.points ] );

		const frames : Map< F , Frame< P2 , CX , FX > > = useMemo( () => {
			const frames : Map< F , Frame< P2 , CX , FX > > = new Map();

			for ( const [ key , val ] of sketch.frames ) {
				const frame : Either< N , Frame< P2 , CX , FX > > = Frm.reify( specks )( val );

				if ( E.isLeft( frame ) ) {
					console.warn( `Frame #${ key }: Missing node ${ frame.left }.` );
					continue;
				}

				frames.set( key , frame.right );
			}

			return frames;
		} , [ specks , sketch.frames ] );

		const rulers : Map< R , Ruler< P2 , RX , LX , Measure< P2 > > > = useMemo( () => {
			const rulers : Map< R , Ruler< P2 , RX , LX , Measure< P2 > > > = new Map();

			for ( const [ key , val ] of sketch.rulers ) {
				const ruler : Either< N , Ruler< P2 , RX , LX , Measure< P2 > > > = Rlr.reify( specks )( val );

				if ( E.isLeft( ruler ) ) {
					console.warn( `Ruler #${ key }: Missing node ${ ruler.left }.` );
					continue;
				}

				rulers.set( key , ruler.right );
			}

			return rulers;
		} , [ specks , sketch.rulers ] );

		const pointViews : ReactElement[] = useMemo( () => [ ...sketch.points.entries() ]
			.map( ( [ key , val ] ) =>
				<PointView
					key={ key }
					value={ val }
					caster={ pipe( caster , center ) }
					preset={ preset.point }
					address={ key }
					hndlrs={ hndlrs?.point }
				/>
			)
		, [ caster , sketch.points , preset.point , hndlrs?.point , center ] );

		const frameViews : ReactElement[] = useMemo( () => [ ...frames.entries() ]
			.map( ( [ key , val ] ) =>
				<FrameView
					key={ key }
					value={ val }
					caster={ center }
					preset={ preset.frame }
					address={ key }
					hndlrs={ hndlrs?.frame }
				/>
			)
		, [ frames , preset.frame , hndlrs?.frame , center ] );

		const pieceViews : ReactElement[] = useMemo( () => [ ...frames.entries() ]
			.map( ( [ key , val ] ) =>
				<FragsView
					key={ key }
					value={ val.shape }
					caster={ center }
					preset={ preset.chunk }
					mode='visual'
					address={ key }
				/>
			)
		, [ frames , preset.chunk , center ] );

		const clickViews : ReactElement[] = useMemo( () => [ ...frames.entries() ]
			.map( ( [ key , val ] ) =>
				<FragsView
					key={ key }
					value={ val.shape }
					caster={ center }
					preset={ preset.chunk }
					mode='handle'
					address={ key }
					hndlrs={ hndlrs?.chunk }
				/>
			)
		, [ frames , preset.chunk , hndlrs?.chunk , center ] );

		const rulerViews : ReactElement[] = useMemo( () => [ ...rulers.entries() ]
			.map( ( [ key , val ] ) =>
				<RulerView
					key={ key }
					value={ val }
					caster={ center }
					preset={ preset.ruler }
					address={ key }
					hndlrs={ hndlrs?.ruler }
					fntsze={ fntsze }
				/>
			)
		, [ rulers , preset.ruler , hndlrs?.ruler , fntsze , center ] );

		return (
			<svg ref={ ref } className={ css.sketch } style={ { '--fntsze' : fntsze } as CSSProperties } { ...props }>
				<g className={ css.frameViews }>{ frameViews }</g>
				<g className={ css.pieceViews }>{ pieceViews }</g>
				<g className={ css.clickViews }>{ clickViews }</g>
				<g className={ css.rulerViews }>{ rulerViews }</g>
				<g className={ css.pointViews }>{ pointViews }</g>
			</svg>
		);
	};


export type PointViewProps< V , N , NX = {} >
	= ViewProps< Point< V , NX > >
	& CasterProps< V >
	& PresetProps< HandleConfig< NX > >
	& Address< PointAddr< N > >
	& HndlrsProps< PointHandlers< N , NX > >
	;

export const PointView = < V , N , NX = {} >( { value , caster , preset , address , hndlrs } : PointViewProps< V , N , NX > ) => {
	const { visual , handle } : HandleConfig< NX > = tightenHandle( preset )( value );
	const render : boolean = visual.render || handle.render;

	if ( !render ) return null;

	const [ x , y ] : P2 = caster( value.point );

	const viscls : string = clss( css.visual , ...( visual?.labels ?? [] ) );
	const hdlcls : string = clss( css.handle , ...( handle?.labels ?? [] ) );

	const message : Message< PointAddr< N > , NX > = mkMsg( address )( handle?.extras?.value );
	const handlers : RegularHandlers< SVGCircleElement > = hndlrs ? toRegular( message )( hndlrs ) : { };

	return (
		<g className={ css.point } data-point={ address }>
			{ !visual.render ? null : <circle cx={ x } cy={ y } className={ viscls } /> }
			{ !handle.render ? null : <circle cx={ x } cy={ y } className={ hdlcls } {...handlers} /> }
		</g>
	);
};


export type PieceMode = 'visual' | 'handle';

export type PieceViewProps< V , F , CX = {} >
	= ViewProps< Piece< V , CX > >
	& CasterProps< V >
	& PresetProps< HandleConfig< CX > >
	& ModeProps< PieceMode >
	& Address< ChunkAddr< F > >
	& HndlrsProps< ChunkHandlers< F , CX > >
	;

export const PieceView = < V , F , CX = {} >( { mode , value , caster , preset , address , hndlrs } : PieceViewProps< V , F , CX > ) => {
	const { visual , handle } : HandleConfig< CX > = tightenHandle( preset )( value );
	const render : boolean = mode === 'visual' ? visual.render : handle.render;

	if ( !render ) return null;

	const path : string =
		Chk.absPath( caster( p( value.first ) ) , Chk.fmap( ( v : V ) => caster( p( v ) ) )( value.chunk ) , caster( p( value.final ) ) );
	const labels : string =
		clss( css.piece , css[ mode ] , ...( mode === 'visual' ? visual?.labels ?? [] : handle?.labels ?? [] ) );

	const message : Message< ChunkAddr< F > , CX > = mkMsg( address )( handle?.extras?.value );
	const handlers : RegularHandlers< SVGPathElement > = mode === 'handle' && hndlrs ? toRegular( message )( hndlrs ) : { };

	return <path d={ path } className={ labels } {...handlers} data-frame={ address[ 0 ] } data-piece={ address[ 1 ] } />;
};


export type FragsViewProps< V , F , CX = {} >
	= ViewProps< Shape< V , Loose< HandleConfig< CX > > > >
	& CasterProps< V >
	& PresetProps< HandleConfig< CX > >
	& ModeProps< PieceMode >
	& Address< ShapeAddr< F > >
	& HndlrsProps< ChunkHandlers< F , CX > >
	;

export const FragsView = < V , F , CX = {} >( { value , address , ...props } : FragsViewProps< V , F , CX > ) => {
	const start : V = value[0][0];
	const items : ReactElement[] = value
		.map( ( [ first , shard ] : [ V , Chunk< V > & Loose< HandleConfig< CX > > ] , i : number ) => {
			const { visual , handle , ...chunk } = shard;
			const final : V = value?.[ i + 1 ]?.[0] ?? start;
			const piece : Piece< V , CX > = { first , chunk , final , visual , handle };

			return <PieceView key={ i } value={ piece } address={ [ address , i ] } { ...props } />;
		} );

	return <g className={ css.frags } data-frame={ address }>{ items }</g>;
};


export type FrameViewProps< V , F , FX = {} >
	= ViewProps< Frame< V , unknown , FX > >
	& CasterProps< V >
	& PresetProps< HandleConfig< FX > >
	& Address< FrameAddr< F > >
	& HndlrsProps< FrameHandlers< F , FX > >
	;

export const FrameView = < V , F , FX = {} >( { value , caster , preset , hndlrs , address } : FrameViewProps< V , F , FX > ) => {
	const { visual , handle } = tightenHandle( preset )( value );

	if ( !visual.render && !handle.render ) return null;

	const shape : Shape< P2 > = Shp.fmap( ( v : V ) => caster( p(v) ) )( value.shape );
	const apath : string = Shp.absPath( shape );

	const viscls : string = clss( css.visual , ...( visual?.labels ?? [] ) );
	const hdlcls : string = clss( css.handle , ...( handle?.labels ?? [] ) );

	const message : Message< FrameAddr< F > , FX > = mkMsg( address )( handle?.extras?.value );
	const handlers : RegularHandlers< SVGCircleElement > = hndlrs ? toRegular( message )( hndlrs ) : { };

	return (
		<g className={ css.frame } data-frame={ address }>
			{ !visual.render ? null : <path d={ apath } className={ viscls } /> }
			{ !handle.render ? null : <path d={ apath } className={ hdlcls } {...handlers} /> }
		</g>
	);
};


export interface LineProps extends SVGProps<SVGLineElement> {
	p1 : P2;
	p2 : P2;
}

export const LineView = ( { p1 , p2 , x1 , y1 , x2 , y2 , ...props } : LineProps ) : ReactElement =>
	<line x1={ x1 ?? p1[0] } y1={ y1 ?? p1[1] } x2={ x2 ?? p2[0] } y2={ y2 ?? p2[1] } {...props} />
;

export type ReadingProps< R , LX >
	= ViewProps< Reading< LX > >
	& PresetProps< HandleConfig< LX > >
	& Address< RulerAddr< R > >
	& HndlrsProps< ReadingHandlers< R , LX > >
	& FntSzeProps
	& { anchor : P2 }
	& { normal : V2 }
	& { measure : V2 }
	& { subject?: V2 }
	;

export const ReadingView = < R , LX >( { value : ruler , anchor , normal , preset , hndlrs , address , measure , subject , fntsze } : ReadingProps< R , LX > ) => {
	const { unit , value , prefix , orient , spacing } = ruler;
	const { visual , handle } = tightenHandle( preset )( ruler );

	const dir : V2 = useMemo( () => {
		const msrDir : V2 = measure;
		const subDir : V2 = subject ?? measure;
		const allDir : Record< Orient , V2 > =
			{ RulerAlong : msrDir
				, RulerAcross : Vec.v2_perp( msrDir )
				, ObjectAlong : subDir
				, ObjectAcross : Vec.v2_perp( subDir )
			};

		return allDir[ orient ?? 'RulerAlong' ];
	} , [ orient , subject , measure ] );

	if ( !visual.render ) return null;

	const ang : number = Math.atan2( dir[ 1 ] , dir[ 0 ] ) * 180 / Math.PI;
	const rot : number = Math.abs( ang ) > 90 ? ang + 180 : ang;

	const pos : P2 = Pnt.add( anchor )( Vec.scale( ( spacing ?? 0 ) * fntsze )( normal ) );

	const message : Message< RulerAddr< R > , LX > = mkMsg( address )( handle?.extras?.value );
	const handlers : RegularHandlers< SVGPathElement > = hndlrs ? toRegular( message )( hndlrs ) : { };

	return (
		<g className={ css.reading }>
			<text
				className={ clss( css.visual , ...( visual?.labels ?? [] ) ) }
				textAnchor='middle'
				dominantBaseline='middle'
				transform={ `translate( ${ pos[ 0 ] } , ${ pos[ 1 ] } ) rotate( ${ rot } )` }
			>
				{ !prefix ? null :
					<tspan className={ css.prefix }>{ prefix }</tspan>
				}
				<tspan className={ css.value }>{ value }</tspan>
				<tspan className={ css.unit }>{ unit }</tspan>
			</text>
			<circle
				className={ clss( css.handle , ...( handle?.labels ?? [] ) ) }
				cx={ pos[ 0 ] }
				cy={ pos[ 1 ] }
				{ ...handlers }
			/>
		</g>
	);
} ;

export type RulerProps< V , R , RX , LX , M >
	= ViewProps< Ruler< V , RX , LX , M > >
	& CasterProps< V >
	& PresetProps< RulerPreset< RX , LX > >
	& Address< RulerAddr< R > >
	& HndlrsProps< RulerHandlers< R , RX , LX > >
	& FntSzeProps
	;

export const RulerView = < V , R , RX , LX >( { value , ...rest } : RulerProps< V , R , RX , LX , Measure< V > > ) =>
	Rlr.isAngle( value )
		? <RulerAngleView value={ value } { ...rest } />
		: <RulerLengthView value={ value } { ...rest } />
		;

export const RulerAngleView = < V , R , RX , LX >( { value , fntsze , caster , preset , hndlrs , address } : RulerProps< V , R , RX , LX , Angle< V > > ) => {
	const { measure , reading , towards } = value;
	const { visual , handle } = tightenHandle( preset )( value );

	if ( ! visual.render ) return null;


	const rad : number = 5 * fntsze;
	const len : number = 2 * fntsze;

	const first : P2 = caster( p( measure.first ) );
	const root : P2 = caster( p( measure.root ) );
	const final : P2 = caster( p( measure.final ) );

	const msrDir : V2 = Vec.unit( Pnt.sub( final )( first ) );
	const hntPnt : P2 = towards ? caster( towards ) : root;
	const hntDir : V2 = Vec.sub( hntPnt )( first );
	const offDir : V2 = Vec.v2_cross( msrDir )( hntDir ) <= 0
		? [ msrDir[ 1 ] , -msrDir[ 0 ] ] // Left perpendicular vector
		: [ -msrDir[ 1 ] , msrDir[ 0 ] ] // Right perpendicular vector
		;

	const fstV : V2 = Pnt.sub( first )( root );
	const finV : V2 = Pnt.sub( final )( root );

	const twdFst : V2 = Vec.unit( fstV );
	const twdFin : V2 = Vec.unit( finV );

	const angle : undefined | number = reading?.value;
	const right : boolean = !!angle && Math.abs( 90 - angle ) <= 0.0001;

	const sze : number = Math.min( right ? len : rad , Vec.norm( fstV ) , Vec.norm( finV ) );

	const fst : P2 = Pnt.add( root )( Vec.scale( sze )( twdFst ) );
	const fin : P2 = Pnt.add( root )( Vec.scale( sze )( twdFin ) );
	const sqr : P2 = Pnt.add( root )( Vec.add( Vec.scale( sze )( twdFst ) )( Vec.scale( sze )( twdFin ) ) );
	const ctr : P2 = root;

	const square : string = `M ${fst[0]} ${fst[1]} L ${ctr[0]} ${ctr[1]} L ${fin[0]} ${fin[1]} L ${sqr[0]} ${sqr[1]} Z`;
	const radial : string = `M ${fst[0]} ${fst[1]} L ${ctr[0]} ${ctr[1]} L ${fin[0]} ${fin[1]} A ${sze} ${sze} 0 0 0 ${fst[0]} ${fst[1]} Z`;

	const anchor : P2 = right || sze < fntsze * 3 ? root : Pnt.mid( fin , fst );

	const message : Message< RulerAddr< R > , RX > = mkMsg( address )( handle?.extras?.value );
	const handlers : RegularHandlers< SVGPathElement > = hndlrs ? toRegular( message )( hndlrs.ruler ) : { };

	return (
		<g className={ clss( css.ruler , css.angle ) }>
			<path className={ css.handle } d={ right ? square : radial } { ...handlers } />
			{ !reading ? null :
				<ReadingView
					value={ reading }
					anchor={ anchor }
					normal={ offDir }
					fntsze={ fntsze }
					preset={ preset.label }
					hndlrs={ hndlrs?.label }
					address={ address }
					measure={ Pnt.sub( fin )( fst ) }
				/>
			}
		</g>
	);
};

export const RulerLengthView = < V , R , RX , LX >( { value , preset , caster , fntsze , hndlrs , address } : RulerProps< V , R , RX , LX , Length< V > > ) => {
	const { subject , measure , spacing , towards , reading , whisker , ...rhandle } = value;
	const { visual , handle } = tightenHandle( preset )( rhandle );

	if ( ! visual.render ) return null;

	const fstPnt : P2 = caster( p( measure.first ) );
	const finPnt : P2 = caster( p( measure.final ) );
	const hntPnt : undefined | P2 = towards ? caster( towards ) : undefined;

	const rlrDst : number = ( spacing ?? 0 ) * fntsze;
	const msrDir : V2 = Vec.unit( Pnt.sub( finPnt )( fstPnt ) );
	const hntDir : V2 = hntPnt
		? Vec.sub( hntPnt )( fstPnt )
		: [ -msrDir[ 1 ] , msrDir[ 0 ] ] // Default right perpendicular vector
		;
	const offDir : V2 = Vec.v2_cross( msrDir )( hntDir ) <= 0
		? [ msrDir[ 1 ] , -msrDir[ 0 ] ] // Left perpendicular vector
		: [ -msrDir[ 1 ] , msrDir[ 0 ] ] // Right perpendicular vector
		;

	const rlrOff : V2 = Vec.scale( rlrDst )( offDir );
	const wskOff : V2 = Vec.scale( fntsze / 2 )( offDir );

	const rp1 : P2 = Pnt.add( fstPnt )( rlrOff );
	const rp2 : P2 = Pnt.add( finPnt )( rlrOff );

	const sp1 : P2 = subject?.first ? caster( subject.first ) : fstPnt;
	const sp2 : P2 = subject?.final ? caster( subject.final ) : finPnt;

	const { standard , extended } : WhiskrConfig = tightenWhiskr( preset.whisk )( whisker );

	const std : string[] = [ css.standard , css.whisker , ...( standard?.labels ?? [] ) ];
	const ext : string[] = [ css.extended , css.whisker , ...( extended?.labels ?? [] ) ];

	const message : Message< RulerAddr< R > , RX > = mkMsg( address )( handle?.extras?.value );
	const handlers : RegularHandlers< SVGPathElement > = hndlrs ? toRegular( message )( hndlrs.ruler ) : { };

	return (
		<g className={ clss( css.ruler , css.length ) }>
			{ !extended.render ? null :
				<>
					<LineView className={ clss( css.first , ...ext ) } p1={ rp1 } p2={ sp1 } />
					<LineView className={ clss( css.final , ...ext ) } p1={ rp2 } p2={ sp2 } />
				</>
			}

			<LineView className={ clss( css.visual , ...( visual?.labels ?? [] ) ) } p1={ rp1 } p2={ rp2 } />

			{ !standard.render ? null :
				<>
					<LineView className={ clss( css.first , ...std ) } p1={ Pnt.add( rp1 )( wskOff ) } p2={ Pnt.subV( rp1 )( wskOff ) } />
					<LineView className={ clss( css.final , ...std ) } p1={ Pnt.add( rp2 )( wskOff ) } p2={ Pnt.subV( rp2 )( wskOff ) } />
				</>
			}

			<LineView className={ clss( css.handle , ...( handle?.labels ?? [] ) ) } p1={ rp1 } p2={ rp2 } { ...handlers } />

			{ !reading ? null :
				<ReadingView
					value={ reading }
					anchor={ Pnt.mid( rp1 , rp2 ) }
					normal={ offDir }
					fntsze={ fntsze }
					preset={ preset.label }
					hndlrs={ hndlrs?.label }
					address={ address }
					measure={ Pnt.sub( rp2 )( rp1 ) }
					subject={ Pnt.sub( sp2 )( sp1 ) }
				/>
			}
		</g>
	);
};

export const SketchExample = () : ReactElement => {
	const sketch : Sketch< number , number , number , P2 > =
		{
			points : new Map(
				[
					[ 0 , { point : [ 0 , 0 ] } ],
					[ 1 , { point : [ 100 , 0 ] } ],
					[ 2 , { point : [ 100 , 100 ] } ],
					[ 3 , { point : [ 0 , 100 ] } ],
				]
			),
			frames : new Map(
				[
					[ 0 , { shape :
						[
							[ 0 , { } ],
							[ 1 , { c0 : 2 } ],
							[ 3 , { } ],
						]
					} ],
				]
			),
			rulers : new Map(
				[
					[ 0 , {
						measure : { first : 0 , final : 2 },
						spacing : 10,
						reading : { unit : 'mm' , value : 300 , spacing : 5 }
					} ] ,
					[ 1 , {
						measure : { first : 3 , root : 0 , final : 1 },
						spacing : 0,
						reading : { unit : 'deg' , value : 90 , spacing : 0 }
					} ]
				]
			),
		};

	const handlers : Handlers< number , number , number > =
	{
		point :
		{
			onClick : ( msg ) => () => {
				if ( !msg )
					console.log( 'No msg' );
				else
					console.log( 'Hello from point: ' , msg.address );
			},
		},
		chunk :
		{
			onClick : ( msg ) => () => {
				if ( !msg )
					console.log( 'No msg' );
				else
					console.log( 'Hello from chunk: ' , msg.address );
			},
		},
		frame :
		{
			onClick : ( msg ) => () => {
				if ( !msg )
					console.log( 'No msg' );
				else
					console.log( 'Hello from frame: ' , msg.address );
			},
		},
		ruler :
		{
			ruler :
			{
				onClick : ( msg ) => () => {
					if ( !msg )
						console.log( 'No msg' );
					else
						console.log( 'Hello from ruler handle: ' , msg.address );
				},
			},
			label :
			{
				onClick : ( msg ) => () => {
					if ( !msg )
						console.log( 'No msg' );
					else
						console.log( 'Hello from ruler reading: ' , msg.address );
				},
			}
		},
	};

	return <SketchView fntsze={ 10 } value={ sketch } caster={ x => x } preset={ preset } hndlrs={ handlers } width="100%" height="100%" />;
};
