import Tooltip from '@mui/material/Tooltip';

import { Price } from '~/src/pricing/price';
import { EditProps } from '~/src/edit/types';
import { pdot , withDefault } from '~/src/base/Optic';
import { comp } from '~/src/base/Function';

export const PriceEdit = ( { value , update , post } : { post : () => void } & EditProps<Price> ) => {
	return (
		<>
			<Tooltip title='Price in USD'>
				<>
				$
					<input
						value={ value.USD ?? '0' }
						onChange={ e => comp(update , pdot('USD') , withDefault( '0' ) )( () => e.target.value ) }
						onBlur={ post }
					/>
				</>
			</Tooltip>

			<div className='call-checkbox'>
				<Tooltip title='Call'>
					<input
						type='checkbox'
						onChange={ e => comp(update, pdot('Call') , withDefault( false ) )( () => e.target.checked ) }
						checked={ value.Call ?? false }
						onBlur={ post }
					/>
				</Tooltip>
			</div>
		</>
	);
};
