import * as E from 'fp-ts/Either';
import { Either } from 'fp-ts/Either';

import Extent , * as Ext from './Extent';

/**
 * The angle measurement. It references 3 points that make an angle. The root
 * point is also the root of an angle.
 */
export interface Angle< N > extends Extent< N > {
	root : N;
}

export default Angle;


/**
 * Used to convert point references into actual points. If it fails to find the
 * point for a specific reference it'll return the reference as the left / error
 * value.
 */
export const reify = < N , V >( cloud : Map< N , V > ) => ( angle : Angle< N > ) : Either< N , Angle< V > > => {
	const root : undefined | V = cloud.get( angle.root );
	if ( !root ) return E.left( angle.root );

	return E.map( ( extent : Extent< V > ) => ( { root , ...extent } ) as Angle< V > )( Ext.reify( cloud )( angle ) );
};
