import * as E from 'fp-ts/Either';
import { Either } from 'fp-ts/Either';

/**
 * Measures the extent of an object by referencing the first and the final point
 * of an object / subject of measurement.
 */
export interface Extent< N > {
	first : N;
	final : N;
}

export default Extent;


/**
 * Used to convert point references into actual points. If it fails to find the
 * point for a specific reference it'll return the reference as the left / error
 * value.
 */
export const reify = < N , V >( cloud : Map< N , V > ) => ( extent : Extent< N > ) : Either< N , Extent< V > > => {
	const first : undefined | V = cloud.get( extent.first );
	if ( !first ) return E.left( extent.first );

	const final : undefined | V = cloud.get( extent.final );
	if ( !final ) return E.left( extent.final );

	return E.right( { first , final } );
};
