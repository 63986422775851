import { Optic , dot , pdot } from '~/src/base/Optic';
import { PieMenuProps } from '~/src/ui/PieMenu';

import { Design } from './Design';

export type SType = 'Edge' | 'Node';

export interface Sels {
	stype : SType;
	frame : number;
	items : number[];
}

export const stype : Optic< Sels , SType > = dot( 'stype' );
export const frame : Optic< Sels , number > = dot( 'frame' );
export const items : Optic< Sels , number[] > = dot( 'items' );

export const mksels = ( stype : SType , frame : number , ...items : number[] ) : Sels =>
	( { stype , frame , items } );

export const rCatSels = ( a : Sels ) => ( b : Sels ) : Sels => {
	if ( a.stype !== b.stype || a.frame !== b.frame )
		return b;

	const nedges : number[] = [
		...( a.items.filter( e => !b.items.includes( e ) ) ),
		...( b.items.filter( e => !a.items.includes( e ) ) ),
	];

	return { stype : b.stype , frame : b.frame , items : nedges };
};

export interface State {
	prev : Design[];
	curr : Design;
	done : boolean;
	sels?: Sels;
	menu?: PieMenuProps;
}

export const prev : Optic< State , Design[] > = dot( 'prev' );
export const curr : Optic< State , Design > = dot( 'curr' );
export const done : Optic< State , boolean > = dot( 'done' );
export const sels : Optic< State , undefined | Sels > = pdot( 'sels' );
export const menu : Optic< State , undefined | PieMenuProps > = pdot( 'menu' );

export const mkstate = ( design : Design ) : State => ( {
	prev : [],
	curr : design,
	done : false,
} );
