import * as A from '~/src/geometry/angle';
import { Fn } from '~/src/base/Function';
import * as Num from '~/src/base/Num';
import { zipMono } from '~/src/base/Array';
import * as One from '~/src/base/Array/One';
import * as Two from '~/src/base/Array/Two';
import * as Three from '~/src/base/Array/Three';
import { Type, number } from 'io-ts';
import { Phantom } from '~/src/base/phantom';

/**
 * Map a function over a vector.
 */
export const map = (f: Fn<number,number>) => <V extends number[]>(v: V): V => v.map(f) as V;

/**
 * Zip two vectors with a function.
 */
export const zip = (f: Fn<number,Fn<number, number>>) => <V extends number[]>(u: V) => (v: V): V =>
	u.map((x, i) => f(x)(v[i] as number)) as V;


/**
 * Add two vectors.
 */
export const add = zip(Num.plus);

/**
 * Subtract the second vector from the first vector.
 */
export const sub = zip(Num.subtract);

/**
 * Dot product between the two vectors.
 */
export const dot = <V extends number[]> (u: V) => (v: V): number => zip(Num.times)(u)(v).reduce((a, x) => a + x, 0);

/**
 * Scale an unknown vector by a some factor.
 */
export const scale = (f: number) => map(Num.times(f));

/**
 * Linear interpolation between two vectors.
 *
 * lerp(0)(u)(v) returns v;
 * lerp(1)(u)(v) return u;
 */
export const lerp = (x: number) => zip(Num.lerp(x));

/**
 * Find the norm/magnitude of a vector, e.g. its length.
 */
export const norm = (xs: number[]): number => Math.sqrt(quadrance(xs));

/**
 * Scale a vector to a particular length.
 */
export const size = (len: number) => <V extends number[]>(xs: V): V => scale(len/norm(xs))(xs);

/**
 * Calculate the unit vector.
 */
export const unit: <V extends number[]>(xs: V) => V = size(1);

/**
 * Find the quandrance of a vector, e.g. the square of its length.
 */
export const quadrance = (xs: number[]): number =>
	xs.map(x => x*x).reduce((x,y) => x+y, 0);

/**
 * Vectors in 1d space.
 */
export type V1 = One.One<number> & { __TYPE__?: 'V1' };
export const V1: Type<V1> = One.One(number);

/**
 * Vectors in 2d space.
 */
export type V2 = Two.Two<number> & { __TYPE__?: 'V2' };
export const V2: Type<V2> = Two.Two(number);

/**
 * Vectors in 3d space.
 */
export type V3 = Phantom<'V3',Three.Three<number>>;
export const V3: Type<V3> = Phantom(Three.Three(number));

/**
 * Add two 1d vectors.
 */
export const v1_add = (a: V1) => (b: V1): V1 =>
	[ a[0] + b[0] ];

/**
 * Add two 2d vectors.
 */
export const v2_add = (a: V2) => (b: V2): V2 =>
	[ a[0] + b[0], a[1] + b[1] ];

/**
 * Add two 3d vectors.
 */
export const v3_add = (a: V3) => (b: V3): V3 =>
	[ a[0] + b[0], a[1] + b[1], a[2] + b[2] ];


/**
 * Subtract two 1d vectors.
 */
export const v1_sub = (a: V1) => (b: V1): V1 =>
	[ a[0] - b[0] ];

/**
 * Subtract two 2d vectors.
 */
export const v2_sub = (a: V2) => (b: V2): V2 =>
	[ a[0] - b[0], a[1] - b[1] ];

/**
 * Subtract two 3d vectors.
 */
export const v3_sub = (a: V3) => (b: V3): V3 =>
	[ a[0] - b[0], a[1] - b[1], a[2] - b[2] ];


/**
 * Multply a scalar into a 2d vector.
 */
export const v2_scale = (x: number) => (b: V2): V2 =>
	[ x * b[0], x * b[1] ];

/**
 * Map a function over the elements of a 3d vector.
 */
export const map_v3 = (f: Fn<number,number>) => (a: V3): V3 =>
	[ f(a[0]), f(a[1]), f(a[2]) ];

/**
 * Convert a 1d vector into a 2d vector.
 */
export const liftX2 = (v1: V1): V2 =>
	[ v1[0], 0 ];

/**
 * Convert a 1d vector into a 3d vector.
 */
export const liftX3 = (v1: V1): V3 =>
	[ v1[0], 0, 0 ];

/**
 * Project a 2d vector into 3d space, onto the XZ plane.
 */
export const liftXZ3 = (v2: V2): V3 =>
	[ v2[0], 0, v2[1] ];

/**
 * Project the XZ plane of a 3d vector into 2d space.
 */
export const lowerXZ3 = (v3: V3): V2 => [ v3[0], -v3[2] ];


/**
 * Apply a linear transformation to a 3d vector.
 */
export const transform3 = (m: Three.Three<Three.Three<number>>) => (v: V3): V3 => [
	v[0]*m[0][0] + v[1]*m[0][1] + v[2]*m[0][2],
	v[0]*m[1][0] + v[1]*m[1][1] + v[2]*m[1][2],
	v[0]*m[2][0] + v[1]*m[2][1] + v[2]*m[2][2],
];

export const about0 = ( a : number ) => ( v : V2 ) : V2 =>
	[ v[ 0 ] * Math.cos( a ) - v[ 1 ] * Math.sin( a )
		, v[ 0 ] * Math.sin( a ) + v[ 1 ] * Math.cos( a )
	];

/**
 * Rotate a 3d vector around the Y axis.
 */
export const aboutY = (a: A.Angle) => (v: V3): V3 => [
	v[0] * A.cos(a) - v[2] * A.sin(a),
	v[1],
	v[0] * A.sin(a) + v[2] * A.cos(a),
];

/**
 * Rotate a 3d vector around the Z axis.
 */
export const aboutZ = (a: A.Angle) => (v: V3): V3 => [
	v[0] * A.cos(a) - v[1] * A.sin(a),
	v[0] * A.sin(a) + v[1] * A.cos(a),
	v[2],
];

/**
 * Lift 2d vector into 3d, rotating around the z axis.
 */
export const liftXZ3_aboutZ = (a: A.Angle) => (v: V2): V3 =>
	aboutZ(a)(liftXZ3(v));

/**
 * CCW / Left 2D perpendicular vector.
 */
export const v2_perp = (v: V2): V2 => [-v[1], v[0]];

/**
 * CCW / Left 2D perpendicular vector.
 */
export const v2_cross = ( v1 : V2 ) => ( v2 : V2 ) : number =>
	v1[0] * v2[1] - v1[1] * v2[0];

/**
 * Extract the first component of vector 'u' using vector 'v' as a basis.
 */
export const firstBasis = <V extends number[]>(u: V) => (v: V): number => dot(u)(v)/quadrance(v);

/**
 * Project a vector u onto a vector v.
 */
export const project = <V extends number[]>(u: V) => (v: V): V => scale(firstBasis(u)(v))(v);

/**
 * Comparing two vectors only keep the smallest components.
 */
export const min = <V extends number[]>(a: V) => (b: V) =>
	zipMono((aa: number) => (bb: number) => Math.min(aa , bb))(a)(b)
;

/**
 * Comparing two vectors only keep the largest components.
 */
export const max = <V extends number[]>(a: V) => (b: V) =>
	zipMono((aa: number) => (bb: number) => Math.max(aa , bb))(a)(b)
;

export const ang = <V extends number[]>(a : V) => (b : V) : number =>
	Math.acos( Math.min( 1 , Math.max( -1 , dot( unit(a) )( unit(b) ) ) ) );
