import * as E from 'fp-ts/Either';
import { Either } from 'fp-ts/Either';

import { P2 } from '~/src/geometry/point';

import * as Linear from './Linear';
import { NoEx , CS , C0 , pp , cr , large , cwise } from './Shared';


/**
 * Simple radial curve representation thac contains a control point that's
 * supposed to be the point lying on the curve between the two other points.
 */
export interface Radial< N > extends C0 < N > {
}

export default Radial;

/**
 * A radial curve with some extra metadata.
 */
export type RadialX< N , X = {} > = Radial< N > & NoEx< X , CS< N > >;

/**
 * The fmap operation over the A parameter.
 */
export const fmap = < A , B >( f : ( r : A ) => B ) => < X >( { c0 , ...rest } : RadialX< A , X > ) : RadialX< B , X > =>
	( { c0 : f( c0 ) , ...rest } ) as RadialX< B , X >;

/**
 * Used to convert point references into actual points. If it fails to find the
 * point for a specific reference it'll return the reference as the left / error
 * value.
 */
export const reify = < N , V >( cloud : Map< N , V > ) => < X >( radial : RadialX< N , X > ) : Either< N , RadialX< V , X > > => {
	const { c0 : tc0 , ...rest } = radial;
	const c0 : undefined | V = cloud.get( tc0 );
	return c0 === undefined ? E.left( tc0 ) : E.right( { c0 , ...rest } as RadialX< V , X > );
};

/**
 * Generates the absolute path string to be used with the SVG's path element.
 */
export const absPath = ( ps : P2 , r : Radial< P2 > , pe : P2 ) : string =>
	`M ${ pp( ps ) } ${ relPath( ps , r , pe ) }`;

/**
 * Generates the relative path string to be used in conjunction with some other
 * function to generate the absolute path.
 */
export const relPath = ( ps : P2 , r : Radial< P2 > , pe : P2 ) : string => {
	const rad : null | number = cr( ps , r.c0 , pe );
	const cws : null | 0 | 1 = cwise( ps , r.c0 , pe );
	const lrg : null | number = !rad ? null : large( rad , ps , r.c0 , pe ) ? 1 : 0;

	if ( rad === null || cws === null || lrg === null ) {
		return Linear.relPath( pe );
	}

	return `A ${ rad } ${ rad } 0 ${ lrg } ${ cws } ${ pp( pe ) }`;
};
