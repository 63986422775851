import { Either } from 'fp-ts/Either';

import Angle , * as Ang from './Angle';
import Length , * as Len from './Length';

/**
 * A general measurement. Right now it can be either an Angle measurement or a
 * Length measurement.
 */
export type Measure< N > = Angle< N > | Length< N >;

export default Measure;


/**
 * Used to convert point references into actual points. If it fails to find the
 * point for a specific reference it'll return the reference as the left / error
 * value.
 */
export const reify = < N , V >( cloud : Map< N , V > ) => ( measure : Measure< N > ) : Either< N , Measure< V > > =>
	isAngle( measure )
		? Ang.reify( cloud )( measure )
		: Len.reify( cloud )( measure );

/**
 * Checks if a Measure is an Angle.
 */
export const isAngle = < N >( msr : Measure< N > ) : msr is Angle< N > =>
	msr.hasOwnProperty( 'root' ) &&
	msr.hasOwnProperty( 'first' ) &&
	msr.hasOwnProperty( 'final' );

/**
 * Checks if a Measure is a Length
 */
export const isLength = < N >( msr : Measure< N > ) : msr is Length< N > =>
	!msr.hasOwnProperty( 'root' ) &&
	msr.hasOwnProperty( 'first' ) &&
	msr.hasOwnProperty( 'final' );
