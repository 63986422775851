import * as React from 'react';

import * as css from './PriceGrid.module.css';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

import { Optic , dot , pdot , withDefault } from '~/src/base/Optic';
import { comp, Mod, Update } from '~/src/base/Function';

import { string , boolean , number } from 'io-ts';
import { PriceGrid , PriceItem , Kind as PKind , KindT as PKindT , KindBool , KindPrice , KindDouble , makeDefaultPriceItem , piValue , KindTuple , KindTTuple , ktupFst , ktupSnd } from '~/src/model/Pricing';
import { View , Name , Plan , Path , Elem , Kind , KindT , Frag , PathKey , showPath , humanFrag } from '~/src/model/Tableau';
import { Requestor } from '~/src/api/Requestor';
import { postPriceGrid , postPriceItem , deletePriceItem } from '~/src/api/Pricing';
import { EditProps } from '~/src/edit/types';
import { PriceEdit } from '~/src/edit/Price';
import { Price } from '~/src/pricing/price';

import ViewEdit from '~/src/edit/View';


// A little helper for generating arrays of numbers from 0 to Math.abs(n)
const arr = (n: number): number[] =>
	Array.from({ length: Math.abs(n) }, (_, idx) => idx);

// Count the number of values in an element.
const elemCounter = (elem: Elem): number =>
	string.is(elem) ? 1 : elem.lst.length;

// Count the number of elements in a single Plan item
const itemCounter = (item: Name<Elem[]>): number =>
	item.item.reduce((acc: number, elm: Elem) => acc + elemCounter(elm), 0);

// Determine the appropriate grid size for a particular plan and view.
const gridSize = (p: Plan, v: View): [number, number] => [
	p
		.filter((n: Name<Elem[]>) => v.Cols.includes(n.name))
		.reduce((acc: number, itm: Name<Elem[]>) => acc * itemCounter(itm), 1),
	p
		.filter((n: Name<Elem[]>) => v.Rows.includes(n.name))
		.reduce((acc: number, itm: Name<Elem[]>) => acc * itemCounter(itm), 1),
];

// Get the numerical path
const pathArray = ( plan: Plan, view: View, [col, row]: [number, number] ) : number[] => {
	const cspans: [string, number][] = spans(plan, view.Cols).map(
		([name, [count, span]]) => [name, Math.floor(col / span) % count]
	);

	const rspans: [string, number][] = spans(plan, view.Rows).map(
		([name, [count, span]]) => [name, Math.floor(row / span) % count]
	);

	const tspans: [string, number][] = cspans.concat(rspans);

	return plan.map((item: Name<Elem[]>) =>
		tspans.reduce( (acc, [name, index]) => (name === item.name ? acc + index : acc) , 0 )
	);
};

// for each name it returns how many items it has and how many columns to span for each item
const spans = (plan: Plan, names: string[]): [string, [number, number]][] => {
	const counts: [string, number][] = names.map((n: string) => [
		n,
		plan
			.filter((itm: Name<Elem[]>) => itm.name === n)
			.reduce((acc: number, itm: Name<Elem[]>) => acc + itemCounter(itm), 0),
	]);

	return counts.map(([name, count], index) => {
		const rest = counts.slice(index + 1).reduce((acc, cnt) => acc * cnt[1], 1);

		return [name, [count, rest]];
	});
};

// Creates fragments from a single Plan item
const fragment = ({ name, item }: Name<Elem[]>): Name<Frag>[] =>
	item
		.map((elem) => {
			if (string.is(elem)) {
				return [{ name: name, item: elem as Frag }] as Name<Frag>[];
			} else {
				return elem.lst.map((val: KindT[Kind]) => {
					return {
						name: name,
						item: { lbl: elem.lbl, knd: elem.knd, val: val } as Frag,
					} as Name<Frag>;
				});
			}
		})
		.reduce((res: Name<Frag>[], arr: Name<Frag>[]) => res.concat(arr));

const arrayPath2Path = (parray: number[], pfrags: Name<Frag>[][]): Path =>
	arr(Math.min(parray.length, pfrags.length)).map(
		i => pfrags[i]![parray[i]!] as unknown as Name<Frag>
	);

// Finds fragment from relIndex, then returns header label
const makeHeader = ( plan : Plan , name : string , idx : number ) : string => {
	const item : Name<Elem[]> | undefined = plan.find( item => item.name === name );
	const frags : Name<Frag>[] = item ? fragment(item) : [];
	const frag : Name<Frag> | undefined = frags[idx];
	return frag ? humanFrag(frag.item) : (name + idx);
};

// Padds a table row with a certain amount of <th> elements
const Paddington = ({ n } : { n : number }) =>
	<>{ arr( n ).map( i => <th key={`padding-${i}`}></th> ) }</>;

// Renders the horizontal header
const HorizontalHeader = ( { plan, view } : { plan: Plan; view: View } ) => {
	const hdrs = spans(plan, view.Cols);

	return (
		<>
			{hdrs.map(([name, [count, span]], idx) => {
				const mult = hdrs
					.slice(0, idx)
					.reduce((acc , hdr) => acc * hdr[1][0], 1);
				return (
					<tr key={`${name}-row-${idx}`}>
						<Paddington n={view.Rows.length} />
						{arr(mult)
							.map((m) =>
								arr(count).map((i) => (
									<th key={`${name}-col-${i}-${m}`} colSpan={span} className={css[`horiz-header-${idx}`]}>
										{ makeHeader(plan , name , i ) }
									</th>
								))
							)
							.reduce((res, itr) => res.concat(itr), [])}
					</tr>
				);
			})}
		</>
	);
};

// Renders the vertical header
const VerticalHeader = ( { colWs , row , plan , view }: { colWs : number[] , row : number , plan : Plan , view : View } ) => {
	const hdrs = spans(plan, view.Rows);
	const wdth = ( i : number ) : number => colWs.slice(0, i).reduce( (acc , w) => acc + w , 0);
	return (
		<>
			{hdrs.map(([name, [count, span]] , idx) => {
				const m = Math.floor(row / span);
				const i = m % count;

				return row % span === 0 ? (
					<th key={`${name}-row-${row}-${i}`} rowSpan={span} className={css[`vert-header-${span}-${idx}`]} style={{ left : wdth(idx) }}>
						{ makeHeader( plan , name , i ) }
					</th>
				) : null;
			})}
		</>
	);
};

const CellEdit = ( { kind , value , update , post } : { kind : PKind , post : () => void } & EditProps<PKindT> ) => {
	if ( KindBool.is( kind ) && boolean.is( value ) ) {
		return (
			<Tooltip title='Toggle'>
				<input type="checkbox" checked={value} onChange={ e => update( () => e.target.checked ) } onBlur={post} />
			</Tooltip>
		);
	} else if ( KindPrice.is( kind ) && Price.is( value ) ) {
		return (
			<PriceEdit value={value} update={update as unknown as Update<Price> } post={post} />
		);
	} else if ( KindDouble.is( kind ) && number.is( value ) ) {
		return (
			<Tooltip title='Numerical value'>
				<input type='number' value={value} onChange={ e => update( () => Number(e.target.value) as number as PKindT ) } onBlur={post} />
			</Tooltip>
		);
	} else if ( KindTuple.is( kind ) && KindTTuple.is( value ) ) {
		return (
			<>
				<CellEdit kind={kind.fst} value={value[0]} update={ comp( update as unknown as Update<KindTTuple>, ktupFst ) } post={post} />
				<CellEdit kind={kind.snd} value={value[1]} update={ comp( update as unknown as Update<KindTTuple>, ktupSnd ) } post={post} />
			</>
		);
	} else {
		return null; // This should not happen (I think)
	}
};

export interface PriceGridProps {
	grid: PriceGrid;
	imap: Record<PathKey, PriceItem>;
}

const grid : Optic<PriceGridProps, PriceGrid> = dot('grid');
const imap : Optic<PriceGridProps, Record<string, PriceItem>> = dot('imap');
const imapx = ( pkey : PathKey ) : Optic<PriceGridProps, PriceItem | undefined> => comp( imap , pdot( pkey ) );

interface ClickerProps {
	action : () => void;
	clicks : number;
	content : string;
}

const Clicker = ( { action , clicks , content } : ClickerProps ) => {
	const clks = Math.abs( clicks );

	const [ counter , setCounter ] = React.useState<number>(clks);

	const handleClick = () => {
		if ( counter <= 0 ) {
			action();
			return;
		}

		setCounter( counter - 1 );
	};

	return <div className={css['clicker']} onClick={handleClick}>{content} { counter < clks ? `(${counter})` : '' }</div>;
};

// Renders the full grid editor
export const PriceGridEdit = ({ value, update, req }: EditProps<PriceGridProps> & { req: Requestor }) => {
	const view : View = value.grid.View ?? {
		Cols: value.grid.Plan.map( prop => prop.name),
		Rows: [],
	};

	const size = gridSize(value.grid.Plan, view);

	const vp = `[cols]:${view.Cols.join('-')}-[rows]:${view.Rows.join('-')}`;

	const pfrags: Name<Frag>[][] = value.grid.Plan.map(fragment);

	const arrP2P = (parr: number[]) => arrayPath2Path(parr, pfrags);

	const updateView : Update<View> = ( viewUpdate : Mod<View> ) => {
		const updatedGrid : PriceGrid = comp( pdot('View') , withDefault( view ) )( viewUpdate )( value.grid );
		comp( update , grid , pdot('View') , withDefault( view ) )( viewUpdate );
		postPriceGrid( req )( updatedGrid );
	};

	const [ colWs , setColWs ] = React.useState<number[]>([]);

	React.useLayoutEffect( () => {
		const table = document.getElementById( 'price-grid' );
		if ( !table ) return;
		const widths = Array.from(table.querySelectorAll('th')).slice(0, view.Rows.length).map(th => th.offsetWidth);
		setColWs( widths );
	}, [ view.Rows.length ] );

	const priceGrid = (
		<table id='price-grid' className={css['price-grid']}>
			<thead>
				<HorizontalHeader plan={value.grid.Plan} view={view} />
			</thead>

			<tbody>
				{arr(size[1]).map((row) => (
					<tr key={`${vp}_row-${row}`}>
						<VerticalHeader colWs={ colWs } row={row} plan={value.grid.Plan} view={view} />
						{arr(size[0]).map((col) => {
							const path = arrP2P( pathArray(value.grid.Plan, view, [col, row]) );
							const pkey = showPath(path);
							const kind : PKind = value.grid.Kind;
							const ttem : PriceItem | undefined = value.imap[pkey];
							// const item : PriceItem = ttem ??

							const idel = () : void => {
								if (ttem) {
									deletePriceItem( req )( ttem.Id )
										.then( () => {
											comp( update , imap )( items => {
												const nitems = {...items};
												delete nitems[pkey];
												return nitems;
											} );
										})
										.catch(console.error);
								}
							};

							const iadd = () : void => {
								const defItem = makeDefaultPriceItem( kind , value.grid.Id , path );
								comp( update , imap )( items => ({...items, [pkey] : defItem }) );
							};

							const handleDelete = (e : React.MouseEvent<HTMLDivElement>) => {
								if (e.shiftKey && e.altKey) {
									idel();
								}
							};

							const cedit = ( item : PriceItem ) => {
								const post : () => void = () =>
									postPriceItem( req )( item );

								const updt : Update<PKindT> = ( f : Mod<PKindT> ) =>
									comp( update , imapx( pkey ) , withDefault( item ) , piValue )( f );

								return (
									<CellEdit
										kind={ kind }
										value={ item.Value }
										update={ updt }
										post={ post }
									/>
								);
							};


							return (
								<td key={ `${vp}-${row}x${col}` }>
									<div className={css['cell-editor']} onClick={handleDelete}>
										{ ttem ? cedit( ttem ) : <Clicker action={ iadd } clicks={5} content='N/A' /> }
									</div>
								</td>
							);
						})}
					</tr>
				))}
			</tbody>
		</table>
	);

	return (
		<Grid container spacing={2} sx={{ height : '100%' }} direction='column'>
			<Grid item sx={{ flex : 0 }}>
				<Grid container spacing={2}>
					<Grid item style={{ display : 'flex' , alignItems : 'center' }}>
						{value.grid.Name}
					</Grid>

					<Grid item>
						<Divider orientation="vertical" />
					</Grid>

					<Grid item>
						<ViewEdit
							value={ view }
							update={ updateView }
						/>
					</Grid>

					<Grid item>
						<Divider orientation="vertical" />
					</Grid>

					<Grid item>
						<ButtonGroup size='small'>
							<Button endIcon={<InfoIcon />}>
								Info
							</Button>
						</ButtonGroup>
					</Grid>
				</Grid>
			</Grid>

			<Grid item sx={{ flex : 1 }}>
				<Box sx={{ width : '100%' , height : '100%' , position : 'relative' }}>
					<Box sx={{ left : 0 , right : 0 , top : 0 , bottom : 0 , position : 'absolute' , overflow : 'auto' }}>
						{ priceGrid }
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};

export default PriceGridEdit;
