import { Type , type as typ , array , string , literal , recursion , boolean , number , union , intersection , partial , tuple } from 'io-ts';

import { Optic , dot , pdot } from '~/src/base/Optic';
import { Price } from '~/src/pricing/price';
import { View , Plan , Path , showPath } from './Tableau';
import { Mod } from '~/src/base/Function';

export type Zone = string;

export type ListId = string;
export type GridId = string;
export type ItemId = string;

// Used to describe the Kind of a values stored in the PriceGrid
export type Kind = KindBool | KindPrice | KindDouble | KindTuple;

export type KindBool = { kind : 'Bool' };

export const KindBool : Type<KindBool> = typ( { kind : literal('Bool') } );

export type KindPrice = { kind : 'Price' };

export const KindPrice : Type<KindPrice> = typ( { kind : literal('Price') } );

export type KindDouble = { kind : 'Double' };

export const KindDouble : Type<KindDouble> = typ( { kind : literal('Double') } );

export type KindTuple = { kind : 'Tuple' , fst : Kind , snd : Kind };

export const ktFst : Optic<KindTuple, Kind> = dot('fst');

export const ktSnd : Optic<KindTuple, Kind> = dot('snd');

export const Kind : Type<Kind> = recursion( 'Kind' , self => union(
	[ KindBool
		, KindPrice
		, KindDouble
		, typ( { kind : literal( 'Tuple' ) , fst : self , snd : self } )
	]
));

export const KindTuple : Type<KindTuple> = typ( { kind : literal('Tuple') , fst : Kind , snd : Kind } );

// Used as a concrete type of a value (should correspond to the Kind)
export type KindT = boolean | Price | number | [ KindT , KindT ];

export const KindT : Type<KindT> = recursion( 'KindT' , self => union(
	[ boolean
		, Price
		, number
		, tuple( [self, self] )
	]
));

export type KindTTuple = [KindT, KindT];

export const ktupFst : Optic<KindTTuple, KindT> = ( tm : Mod<KindT> ) => ( [ fst , snd ] : KindTTuple ) =>
	[ tm(fst) , snd ] as KindTTuple;

export const ktupSnd : Optic<KindTTuple, KindT> = ( tm : Mod<KindT> ) => ( [ fst , snd ] : KindTTuple ) =>
	[ fst , tm(snd) ] as KindTTuple;

export const KindTTuple : Type<KindTTuple> = tuple( [ KindT , KindT ] );

export const defaultKindT = ( kind : Kind ) : KindT => {
	if ( KindBool.is( kind ) ) {
		return false as KindT;
	} else if ( KindPrice.is( kind ) ) {
		return { USD : '0' } as Price as KindT;
	} else if ( KindDouble.is( kind ) ) {
		return 0 as KindT;
	} else {
		return [ defaultKindT( kind.fst ) , defaultKindT( kind.snd ) ] as KindT;
	}
};

export interface PriceElem {
	Grid : GridId;
}

export const PriceElem : Type<PriceElem> = typ({
	Grid : string
});

export interface PriceList {
	Id : ListId;
	Zone : Zone;
	Name : string;
	Elems : PriceElem[];
}

export const plElems : Optic<PriceList, PriceElem[]> = dot('Elems');

export const PriceList : Type<PriceList> = typ({
	Id : string,
	Zone : string,
	Name : string,
	Elems : array(PriceElem),
});

export interface PriceGrid {
	Id : GridId;
	Name : string;
	Plan : Plan;
	Kind : Kind;
	Many : boolean;
	View?: View;
}

export const priceGridId : Optic<PriceGrid , GridId> = dot('Id');
export const priceGridName : Optic<PriceGrid , string> = dot('Name');
export const priceGridPlan : Optic<PriceGrid , Plan> = dot('Plan');
export const priceGridKind : Optic<PriceGrid , Kind> = dot('Kind');
export const priceGridMany : Optic<PriceGrid , boolean> = dot('Many');
export const priceGridView : Optic<PriceGrid , View | undefined> = pdot('View');

export const PriceGrid : Type<PriceGrid> = intersection(
	[ typ({
		Id : string,
		Name : string,
		Plan : Plan,
		Kind : Kind,
		Many : boolean,
	})
	, partial( { view : View } )
	]
);

export interface PriceItem {
	Id : string;
	Grid : string;
	Path : Path;
	Value : KindT;
}

export const PriceItem : Type<PriceItem> = typ({
	Id : string,
	Grid : string,
	Path : Path,
	Value : KindT,
});

export const piValue : Optic<PriceItem, KindT> = dot( 'Value' );

export const makePriceItemId = ( gid : GridId , path : Path ) =>
	`grid-${gid}_path-${showPath(path)}`;

export const makeDefaultPriceItem = ( knd : Kind , gid : GridId , path : Path ) : PriceItem => ({
	Id : makePriceItemId( gid , path ),
	Grid : gid,
	Path : path,
	Value : defaultKindT( knd ),
});
