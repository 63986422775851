import * as E from 'fp-ts/Either';
import { Either } from 'fp-ts/Either';

import { P2 } from '~/src/geometry/point';

import { NoEx , C0 , C1 , pp } from './Shared';


/**
 * A simple bezier curve representation that contains two control points C0
 * and C1
 */
export interface Bezier< N > extends C0< N > , C1< N > {
}

export default Bezier;

/**
 * A bezier curve with some extra metadata attached to it.
 */
export type BezierX< N , X = {} > = Bezier< N > & NoEx< X , Bezier< N > >;

/**
 * The fmap operation over the A parameter.
 */
export const fmap = < A , B >( f : ( b : A ) => B ) => < X >( { c0 , c1 , ...rest } : BezierX< A , X > ) : BezierX< B , X > =>
	( { c0 : f( c0 ) , c1 : f( c1 ) , ...rest } ) as BezierX< B , X >;

/**
 * Used to convert point references into actual points. If it fails to find the
 * point for a specific reference it'll return the reference as the left / error
 * value.
 */
export const reify = < N , V >( cloud : Map< N , V > ) => < X >( bezier : BezierX< N, X > ) : Either< N , BezierX< V , X > > => {
	const { c0 : tc0 , c1 : tc1 , ...rest } = bezier;

	const c0 : undefined | V = cloud.get( tc0 );
	const c1 : undefined | V = cloud.get( tc1 );

	if ( c0 === undefined )
		return E.left( tc0 );

	if ( c1 === undefined )
		return E.left( tc1 );

	return E.right( { c0 , c1 , ...rest } as BezierX< V , X > );
};

/**
 * Generates the absolute path string to be used with the SVG's path element.
 */
export const absPath = ( ps : P2 , b : Bezier< P2 > , pe : P2 ) : string =>
	`M ${ pp( ps ) } ${ relPath( b , pe ) }`;

/**
 * Generates the relative path string to be used in conjunction with some other
 * function to generate the absolute path.
 */
export const relPath = ( b : Bezier< P2 > , pe : P2 ) : string =>
	`C ${ pp( b.c0 ) } ${ pp( b.c1 ) } ${ pp( pe ) }`;
