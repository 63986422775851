/**
 * App.
 *
 * The main app file where the rest branch out.
 *
 * @since 0.1.0
 */
import { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route } from 'react-router-dom';

import {
	Experimental_CssVarsProvider as CssVarsProvider,
} from '@mui/material/styles';

import { Requestor } from '~/src/api/Requestor';

import Login from './pages/login';
import Logout from './pages/logout';
import Opening from './pages/opening';
import Template from './pages/template';
import ClientTemplateMod from './pages/clientTemplateMod';
import LimitPage from './pages/template-limits';
import ConfigTablePage from './pages/configs';
import TestPage from './pages/test';
import Pricing from './pages/pricing';
import BulkDesignPage from './pages/bulk-design';
import EmbedBulkDesignPage from './pages/embed-bulk-design';
import FigurePage from './pages/figure';
import { SketchExample } from './sketch/Sketch';
import { GUI } from './gui/GUI';

import theme from '~/src/ui/theme/cssvar';
import ScrollContent from '~/src/ui/ScrollContent';
import { HeaderBar } from './ui/headerbar/HeaderBar';
import { defaultSettings, Settings, SettingsProvider } from '~/src/user/settings';

import { getConfig } from '~/src/config';

/**
 * App Props.
 *
 * @since 0.2.0
 */
export type AppProps = unknown;

const App: FC<AppProps> = () => {
	const [req, setReq] = useState<Requestor | undefined>(undefined);
	const [settings, _updateSettings] = useState<Settings>(defaultSettings());
	const config = getConfig(process.env['STAGE'])('local');

	const login = <Login config={config} updateReq={setReq}/>;

	return (
		<>
			<Helmet>
				<title>Quartz</title>
				<meta name="mobile-web-app-capable" content="yes" />
			</Helmet>

			<SettingsProvider value={settings}>
				<CssVarsProvider defaultMode="dark" theme={theme}>
					<Routes>
						<Route path="embed/bulk-design" />
						<Route path="*" element={ <HeaderBar req={req} /> } />
					</Routes>

					<main id="main">
						<Routes>
							<Route path="test" element={ <TestPage/> }/>
							<Route path="opening" element={ !req ? login : <Opening req={req}/> }/>
							<Route path="template" element={ !req ? login : <Template req={req}/> }/>
							<Route path="template-mod" element={ !req ? login : <ClientTemplateMod req={req}/> }/>
							<Route path="template-limits" element={ !req ? login : <LimitPage req={req}/> }/>
							<Route path="config-table" element={ !req ? login : <ConfigTablePage req={req}/> }/>
							<Route path="figure" element={ !req ? login : <FigurePage req={req} /> }/>
							<Route path="pricing" element={ !req ? login : <Pricing req={req} /> } />
							<Route path="bulk-design" element={!req ? login : <ScrollContent><BulkDesignPage req={req}/></ScrollContent> }/>
							<Route path="embed/bulk-design" element={<EmbedBulkDesignPage config={config} />}/>
							<Route path="login" element={ !req ? login : null }/>
							<Route path="logout" element={ <Logout updateReq={setReq} /> }/>
							<Route path="sketch" element={ <SketchExample /> } />
							<Route path="gui" element={ !req ? login : <GUI req={req}/> }/>
						</Routes>
					</main>
				</CssVarsProvider>
			</SettingsProvider>
		</>
	);
};

export default App;
