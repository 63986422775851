import { Type } from 'io-ts';
import * as Either from 'fp-ts/Either';

import { V1, V2, V3 } from '~/src/geometry/vector';
import * as V from '~/src/geometry/vector';
import { Fn } from '~/src/base/Function';
import { Three } from '~/src/base/Array/Three';
import { head } from 'fp-ts/lib/NonEmptyArray';
import { NonEmpty } from '~/src/base/Array/NonEmpty';

export type P<T> = T & { __TYPE__? : 'P'}

/**
 * Change something of type T into a thing of type P<T>.
 */
export const p = <T>(x: T): P<T> => x as P<T>;

export const P = <T,S>(codecT: Type<T,S>) => new Type<P<T>,P<S>>(
	`P<${codecT.name}>`,
	(x: unknown): x is P<T> => codecT.is(x),
	(input,context) => Either.map<T,P<T>>(mk)(codecT.validate(input,context)),
	(x: P<T>) => codecT.encode(x) as P<S>,
);

export const mk = p;

export const unP = <T>(p : P<T>) : T => p as unknown as T;

/**
 * Add a vector to a point of unknown size.
 */
export const add = <V extends number[]>(p: P<V>) => (v: V): P<V> =>
	V.add(p)(v);

/**
 * Subtract the second point from the first point, resulting in a vector.
 */
export const sub = <V extends number[]>(p: P<V>) => (q: P<V>): V =>
	V.sub(p)(q);

/**
 * Find a mid-point.
 */
export const mid = <V extends number[]>(...pts : P<V>[]) : P<V> =>
	V.scale( 1 / pts.length )( pts.reduce( ( acc , val ) => V.add( acc )( val ) ) );

/**
 * Subtract a vector from the first point, resulting in a point.
 */
export const subV = <V extends number[]>(p: P<V>) => (q: V): P<V> =>
	V.sub(p)(q);

/**
 * Linear interpolation between two points.
 *
 * lerp(0)(p)(q) returns q;
 * lerp(1)(p)(q) return p;
 */
export const lerp = (x: number) => <V extends number[]>(p: P<V>) => (q: P<V>): P<V> =>
	V.lerp(x)(p)(q);

/**
 * Points in 1d space.
 */
export type P1 = P<V1>;

export const P1 = P(V1);

/**
 * Points in 2d space.
 */
export type P2 = P<V2>;

export const P2 = P(V2);

/**
 * Points in 3d space.
 */
export type P3 = P<V3>;

export const P3 = P(V3);

/**
 * Add a 3d vector to a 3d point.
 */
export const p3_add = (p: P3, v: V3): P3 => [
	p[0] + v[0],
	p[1] + v[1],
	p[2] + v[2],
];

/**
 * Subject 3d point `q` from 3d point `p`, resulting in a 3d vector.
 */
export const p3_sub = (p: P3, q: P3): V3 => [
	q[0] - p[0],
	q[1] - p[1],
	q[2] - p[2],
];

/**
 * Fix a 3d vector in space relative to a 3d point.
 *
 * Same as just adding that vector to the point.
 */
export const fix = p3_add;


/**
 * Projections from 3d space to 2d space.
 */
export type Projection = Fn<P3,P2>;

/**
 * Three dimensional rotation matricies.
 */
export type Rotation3 = Three<Three<number>>;

/**
 * Isometric rotation in three dimensions.
 */
export const isometric_rotation: Rotation3 = [
	[ Math.sqrt(1/2), -Math.sqrt(1/2), 0 ],
	[ Math.sqrt(1/6), Math.sqrt(1/6), Math.sqrt(2/3) ],
	[ Math.sqrt(1/3), Math.sqrt(1/3), -Math.sqrt(1/3) ],
];

/**
 * Apply a function to the vector inside a point.
 */
export const map = <T,S>(f: Fn<T,S>) => (p: P<T>): P<S> => f(p) as P<S>;

/**
 * Apply a linear transformation to a 3d point.
 */
export const transform3 = (m: Rotation3): Fn<P3,P3> => map(V.transform3(m));

export const isometric: (p: P3) => P3 = transform3(isometric_rotation);

export const lowerXZ3: Projection = map(V.lowerXZ3);

export const liftXZ3: (p: P2) => P3 = map(V.liftXZ3);

export const isoXZ: Projection = (p: P3): P2 => lowerXZ3(isometric(p));

export const near = <V extends number[]>(a: P<V>) => (b: P<V>): boolean =>
	V.quadrance(sub(a)(b)) >= 0.000001
;

export const bbox = <V extends number[]>(pts: NonEmpty<P<V>>) : [P<V> , P<V>] => pts
	.reduce(([minP, maxP], pnt) =>
		[ p(V.min(minP)(pnt))
			, p(V.max(maxP)(pnt))
		]
	, [head(pts), head(pts)] as [P<V>, P<V>]
	)
;
