import { Type , type , record , literal , number , string , tuple , union , success , failure } from 'io-ts';

import { Mod } from '~/src/base/Function';
import { P } from '~/src/geometry/point';

export interface CW< N > {
	type : 'CW';
	value : [ N , N , N ];
}

export const CW = < N >( n : Type< N > ) : Type< CW< N > > => type( {
	type : literal( 'CW' ),
	value : tuple( [ n , n , n ] ),
} , `CW<${n.name}>` );

export const cwN = < N >( f : Mod< N > ) => ( cw : CW< N > ) : CW< N > => {
	const { value : [ a , b , c ] } = cw;

	const na = f( a );
	const nb = f( b );
	const nc = f( c );

	if ( na === a && nb === b && nc === c ) return cw;

	return { type : 'CW' , value : [ na , nb , nc ] };
};


export interface CCW< N > {
	type : 'CCW';
	value : [ N , N , N ];
}

export const CCW = < N >( n : Type< N > ) : Type< CCW< N > > => type( {
	type : literal( 'CCW' ),
	value : tuple( [ n , n , n ] ),
} , `CCW<${n.name}>` );

export const ccwN = < N >( f : Mod< N > ) => ( ccw : CCW< N > ) : CCW< N > => {
	const { value : [ a , b , c ] } = ccw;

	const na = f( a );
	const nb = f( b );
	const nc = f( c );

	if ( na === a && nb === b && nc === c ) return ccw;

	return { type : 'CCW' , value : [ na , nb , nc ] };
};


export type Selector< N > = CW< N > | CCW< N >;

export const Selector = < N >( n : Type< N > ) : Type< Selector< N > > =>
	union( [ CW( n ) , CCW( n ) ] , `Selector<${n.name}>` );


export interface Angle< N > {
	type : 'Angle';
	value : [ N , N , N , number ];
}

export const Angle = < N >( n : Type< N > ) : Type< Angle< N > > => type( {
	type : literal( 'Angle' ),
	value : tuple( [ n , n , n , number ] ),
} , `Angle<${n.name}>` );

export const ang = < N >( a : N , b : N , c : N , ang : number ) : Angle< N > => ( {
	type : 'Angle',
	value : [ a , b , c , ang ],
} );

export const aval = < N >( f : Mod< number > ) => ( ang : Angle< N > ) : Angle< N > => {
	const [ a , b , c , v ] = ang.value;
	const nv : number = f( v );

	return v === nv ? ang : { ...ang , value : [ a , b , c , nv ] };
};

export const angN = < N >( f : Mod< N > ) => ( angle : Angle< N > ) : Angle< N > => {
	const { value : [ a , b , c , v ] } = angle;

	const na = f( a );
	const nb = f( b );
	const nc = f( c );

	if ( na === a && nb === b && nc === c ) return angle;

	return { type : 'Angle' , value : [ na , nb , nc , v ] };
};



export interface Distance< N > {
	type : 'Distance';
	value : [ N , N , number ];
}

export const Distance = < N >( n : Type< N > ) : Type< Distance< N > > => type( {
	type : literal( 'Distance' ),
	value : tuple( [ n , n , number ] ),
} , `Distance<${n.name}>` );

export const dst = < N >( a : N , b : N , dst : number ) : Distance< N > => ( {
	type : 'Distance',
	value : [ a , b , dst ],
} );

export const dval = < N >( f : Mod< number > ) => ( dst : Distance< N > ) : Distance< N > => {
	const [ a , b , v ] = dst.value;
	const nv : number = f( v );

	return v === nv ? dst : { ...dst , value : [ a , b , nv ] };
};

export const dstN = < N >( f : Mod< N > ) => ( distance : Distance< N > ) : Distance< N > => {
	const { value : [ a , b , v ] } = distance;

	const na = f( a );
	const nb = f( b );

	if ( na === a && nb === b ) return distance;

	return { type : 'Distance' , value : [ na , nb , v ] };
};


export type Constraint< N > = Angle< N > | Distance< N >;

export const Constraint = < N >( n : Type< N > ) : Type< Constraint< N > > =>
	union( [ Angle( n ) , Distance( n ) ] , `Constraint<${n.name}>` );



export type Config< N extends string | number | symbol , V > = Record< N , P< V > >;

export const Config = < N extends string | number | symbol >( n : Type< N , N , unknown > ) => < V >( v : Type< V > ) : Type< Config< N , V > > =>
	record( n , P( v ) , `Config<${n.name}, ${v.name}>` );

export const RecKey = < A >( fstr : ( s : string ) => null | A , codec : Type< A > ) : Type< A , A , unknown > => new Type(
	`Reckey<${codec.name}>`,
	codec.is,
	( input , context ) => {
		if ( string.is( input ) ) {
			const value : null | A = fstr( input );
			return value === null ? failure( input , context ) : success( value );
		}

		return codec.validate( input , context );
	},
	x => x
);


export interface CacheMiss {
	type : 'CacheMiss';
	value : number;
}

export const CacheMiss : Type< CacheMiss > = type( {
	type : literal( 'CacheMiss' ),
	value : number,
} , 'CacheMiss' );


export interface NoCluster {
	type : 'NoCluster';
	value : number;
}

export const NoCluster : Type< NoCluster > = type( {
	type : literal( 'NoCluster' ),
	value : number,
} , 'NoCluster' );


export interface BadType< T > {
	type : 'BadType';
	value : [ T , number ];
}

export const BadType = < T >( t : Type< T > ) : Type< BadType< T > > => type( {
	type : literal( 'BadType' ),
	value : tuple( [ t , number ] ),
} , `BadType<${t.name}>` );


export type Rigid = 'Rigid';
export const Rigid : Type< Rigid > = literal( 'Rigid' );

export type Radial = 'Radial';
export const Radial : Type< Radial > = literal( 'Radial' );

export type Scalable = 'Scalable';
export const Scalable : Type< Scalable > = literal( 'Scalable' );


export type BadRigid = BadType< Rigid >;
export const BadRigid : Type< BadRigid > = BadType( Rigid );

export type BadRadial = BadType< Radial >;
export const BadRadial : Type< BadRadial > = BadType( Radial );

export type BadScalable = BadType< Scalable >;
export const BadScalable : Type< BadScalable > = BadType( Scalable );


export interface Impossible {
	type : 'Impossible';
	value : string;
}

export const Impossible : Type< Impossible > = type( {
	type : literal( 'Impossible' ),
	value : string,
} , 'Impossible' );


export interface FetchError< N > {
	type : 'FetchError';
	value : N;
}

export const FetchError = < N >( n : Type< N > ) : Type< FetchError< N > > => type( {
	type : literal( 'FetchError' ),
	value : n,
} , 'FetchError' );


export type SolverErrs< N >
	= CacheMiss
	| NoCluster
	| BadRigid
	| BadRadial
	| BadScalable
	| Impossible
	| FetchError< N >
	;

export const SolverErrs = < N >( n : Type< N > ) => union(
	[ CacheMiss , NoCluster , BadRigid , BadRadial , BadScalable , Impossible , FetchError( n ) ]
);

export interface Simple< N = number > {
	Selectors : Selector< N >[];
	Constraints : Constraint< N >[];
}
