import Design from './Design';
import * as Index from './Index';
import { len , ang , rnd } from './Constraint';

export type Inits = 'house' | 'rectangle' | 'rounded_corner';
export const Inits : Inits[] = [ 'house' , 'rectangle' , 'rounded_corner' ];

export const inits : Record< Inits , Design > = {
	'house' : {
		pnts : Index.make(),
		cons : Index.make( [
			len( 0 , 1 )( 400 ),
			len( 1 , 2 )( 400 ),
			len( 3 , 0 )( 400 ),
			ang( 0 , 1 , 2 )( 90 )( 'CCW' ),
			ang( 3 , 0 , 1 )( 90 )( 'CCW' ),

			len( 1 , 4 )( 100 ),
			ang( 1 , 4 , 2 )( 180 )( 'CCW' ),

			len( 5 , 0 )( 100 ),
			ang( 3 , 5 , 0 )( 180 )( 'CCW' ),

			len( 2 , 6 )( 200 ),
			ang( 2 , 6 , 3 )( 180 )( 'CCW' ),

			rnd( 4 , 6 , 5 )( 50 ),
		] ),

		pics : [
			[
				[ 0 , {} ],
				[ 1 , {} ],
				[ 4 , {} ],
				[ 10 , { c0 : 12 } ],
				[ 11 , {} ],
				[ 5 , {} ],
			]
		],
	},

	'rectangle' : {
		pnts : Index.make(),
		cons : Index.make( [
			len( 0 , 1 )( 400 ),
			len( 1 , 2 )( 200 ),
			len( 3 , 0 )( 200 ),
			ang( 0 , 1 , 2 )( 90 )( 'CCW' ),
			ang( 3 , 0 , 1 )( 90 )( 'CCW' ),
		] ),
		pics : [
			[
				[ 0 , {} ],
				[ 1 , {} ],
				[ 2 , {} ],
				[ 3 , {} ],
			]
		],
	},

	'rounded_corner' : {
		pnts : Index.make(),
		cons : Index.make( [
			len( 0 , 1 )( 400 ),
			len( 1 , 2 )( 200 ),
			len( 3 , 0 )( 200 ),
			ang( 0 , 1 , 2 )( 90 )( 'CCW' ),
			ang( 3 , 0 , 1 )( 90 )( 'CCW' ),
			rnd( 0 , 1 , 2 )( 50 ),
		] ),
		pics : [
			[
				[ 7 , { c0 : 9 } ],
				[ 8 , {} ],
				[ 2 , {} ],
				[ 3 , { c0 : 0 } ],
			]
		],
	},
};

export default inits;
