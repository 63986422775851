import { Loose , Tight } from './Utils';

/**
 * Configuration for purely visual elements like points and lines. It contains
 * a flag that tells us if the element should be rendered or not, and a list of
 * labels that should be injected as a CSS class names, and can potentially be
 * used for some form of filtering or decision making.
 */
export interface VisualConfig {
	render : boolean;
	labels?: string[];
}

/**
 * Used to merge a preset with custom configuration. If user has provided some
 * configuration it'll override any provided preset.
 */
export const tightenVisual = ( preset : VisualConfig ) => ( config ?: Loose< VisualConfig > ) : VisualConfig => {
	const render : boolean = config?.render ?? preset.render;
	const labels : string[] = config?.labels ?? [];
	return ( { render , ...( labels.length === 0 ? {} : { labels } ) } );
};


/**
 * Used to attach any extra metadata to a Sketch element. This data will be
 * provided to any event listeners attached to the components.
 */
export interface ExtrasConfig< E > {
	extras?: Tight< E >;
}

/**
 * Used to merge a preset with custom configuration. If user has provided some
 * configuration it'll override any provided preset.
 */
export const tightenExtras = < E >( preset : ExtrasConfig< E > ) => ( config ?: Loose< ExtrasConfig< E > > ) : ExtrasConfig< E > => {
	return config?.extras ? config : preset ;
};

/**
 * Anything that can be considered a "handle", basically, a visual element that
 * users can interact with. There's an option for how to render the purely
 * visual part, and the part that's supposed to exist only for the sake of user
 * interactions.
 */
export interface HandleConfig< E > {
	visual : VisualConfig;
	handle : VisualConfig & ExtrasConfig< E >;
}

export const hextras = < E >( e : E ) : Loose< HandleConfig< E > > =>
	( { handle : { extras : { tight : true , value : e } } } );

/**
 * Used to merge a preset with custom configuration. If user has provided some
 * configuration it'll override any provided preset.
 */
export const tightenHandle = < E, >( preset : HandleConfig< E > ) => ( config ?: Loose< HandleConfig< E > > ) : HandleConfig< E > => {
	const visual : VisualConfig = tightenVisual( preset.visual )( config?.visual );
	const visualH : VisualConfig = tightenVisual( preset.handle )( config?.handle );
	const extrasH : ExtrasConfig< E > = tightenExtras( preset.handle )( config?.handle );

	return ( { visual , handle : { ...visualH , ...extrasH } } );
};

/**
 * Configuration for the ruler whiskers.
 */
export interface WhiskrConfig {
	standard : VisualConfig;
	extended : VisualConfig;
}

/**
 * Used to merge a preset with custom configuration. If user has provided some
 * configuration it'll override any provided preset.
 */
export const tightenWhiskr = ( preset : WhiskrConfig ) => ( config ?: Loose< WhiskrConfig > ) : WhiskrConfig => (
	{ standard : tightenVisual( preset.standard )( config?.standard )
		, extended : tightenVisual( preset.extended )( config?.extended )
	}
);
