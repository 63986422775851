import * as E from 'fp-ts/Either';
import { Either } from 'fp-ts/Either';

import { P2 } from '~/src/geometry/point';
import { Mod } from '~/src/base/Function';

import { NoEx , CS } from './Chunk/Shared';
import Linear , * as Lin from './Chunk/Linear';
import Radial , * as Rad from './Chunk/Radial';
import Bezier , * as Bez from './Chunk/Bezier';

/**
 * A part of some greater shape. Currently it can be Linear, Radial or Bezier
 * curve.
 */
export type Chunk< N > = Linear | Radial< N > | Bezier< N >;

export default Chunk;

export const chunkN = < N >( f : Mod< N > ) => ( chunk : Chunk< N > ) : Chunk< N > => {
	if ( isBezier( chunk ) ) {
		const bc0 : N = f( chunk.c0 );
		const bc1 : N = f( chunk.c1 );

		if ( bc0 === chunk.c0 && bc1 === chunk.c1 )
			return chunk;

		return { c0 : bc0 , c1 : bc1 };
	}

	if ( isRadial( chunk ) ) {
		const rc0 : N = f( chunk.c0 );

		return rc0 === chunk.c0 ? chunk : { c0 : rc0 };
	}

	return chunk;
};

/**
 * A chunk with some metadata attached to it.
 */
export type ChunkX< N , X = {} > = Chunk< N > & NoEx< X , CS< N > >;

/**
 * The fmap operation over the A parameter.
 */
export const fmap = < A , B >( f : ( c : A ) => B ) => < X >( chunk : ChunkX< A , X > ) : ChunkX< B , X > => {
	if ( isBezier( chunk ) )
		return Bez.fmap( f )( chunk );

	if ( isRadial( chunk ) )
		return Rad.fmap( f )( chunk );

	return chunk as Omit< X , keyof CS< B > > as ChunkX< B , X >;
};

/**
 * Used to convert point references into actual points. If it fails to find the
 * point for a specific reference it'll return the reference as the left / error
 * value.
 */
export const reify = < N , V >( cloud : Map< N , V > ) => < X extends object >( chunk : ChunkX< N , X > ) : Either< N , ChunkX< V , X > > => {
	if ( isBezier( chunk ) )
		return Bez.reify( cloud )( chunk );

	if ( isRadial( chunk ) )
		return Rad.reify( cloud )( chunk );

	return E.right( chunk as Omit< X , keyof CS< V > > as ChunkX< V , X > );
};

/**
 * Generates the absolute path string to be used with the SVG's path element.
 */
export const absPath = ( ps : P2 , chunk : Chunk< P2 > , pe : P2 ) : string => {
	if ( isBezier( chunk ) )
		return Bez.absPath( ps , chunk , pe );

	if ( isRadial( chunk ) )
		return Rad.absPath( ps , chunk , pe );

	return Lin.absPath( ps , pe );
};

/**
 * Checks if a Chunk is a Linear chunk.
 */
export const isLinear = < N >( c : Chunk< N > ) : c is Linear =>
	!( c.hasOwnProperty( 'c0' ) || c.hasOwnProperty( 'c1' ) );

/**
 * Checks if a Chunk is a Radial chunk.
 */
export const isRadial = < N >( c : Chunk< N > ) : c is Radial< N > =>
	c.hasOwnProperty( 'c0' ) && !c.hasOwnProperty( 'c1' );

/**
 * Checks if a Chunk is a Bezier chunk.
 */
export const isBezier = < N >( c : Chunk< N > ) : c is Bezier< N > =>
	c.hasOwnProperty( 'c0' ) && c.hasOwnProperty( 'c1' );
