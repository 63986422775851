import { P2 } from '~/src/geometry/point';

import { pp } from './Shared';

/**
 * Just a simple straight line.
 */
export interface Linear {
}

export default Linear;


/**
 * Generates the absolute path string to be used with the SVG's path element.
 */
export const absPath = ( ps : P2 , pe : P2 ) : string =>
	`M ${ pp( ps ) } ${ relPath( pe ) }`;

/**
 * Generates the relative path string to be used in conjunction with some other
 * function to generate the absolute path.
 */
export const relPath = ( pe : P2 ) : string =>
	`L ${ pp( pe ) }`;
