import { comp } from '~/src/base/Function';
import { Optic , dot , optIn } from '~/src/base/Optic';
import { V2 } from '~/src/geometry/vector';
import { P2 , p } from '~/src/geometry/point';
import { Simple } from '~/src/geosol/Model';

import Ruler from '~/src/sketch/Model/Ruler';
import Angle from '~/src/sketch/Model/Ruler/Angle';
import Length from '~/src/sketch/Model/Ruler/Length';
import Extent from '~/src/sketch/Model/Ruler/Extent';
import Reading from '~/src/sketch/Model/Ruler/Reading';
import Chunk from '~/src/sketch/Model/Chunk';
import { Shape , Basic } from '~/src/sketch/Model/Shape';
import Frame from '~/src/sketch/Model/Frame';
import { Loose } from '~/src/sketch/Model/Utils';
import { HandleConfig , hextras } from '~/src/sketch/Model/Config';
import * as S from '~/src/sketch/Sketch';
import { Point } from '~/src/sketch/Sketch';

import { Index , item } from './Index';
import { Con , Rnd , Raw , Ang , Len , Out , Wise , isAng , ang2sel , conVal , conLen , conAng , conWse , lval , aval , cons2raws , nextIdx } from './Constraint';


export interface Design {
	pnts : Index< P2 >;
	cons : Index< Con >;
	pics : Basic< number >[];
}

export const pnts : Optic< Design , Index< P2 > > = dot( 'pnts' );
export const cons : Optic< Design , Index< Con > > = dot( 'cons' );
export const pics : Optic< Design , Basic< number >[] > = dot( 'pics' );

export const con = ( cid : number ) : Optic< Design , undefined | Con > =>
	comp( cons , item( cid ) );

export const cval = ( cid : number ) : Optic< Design , undefined | number > =>
	comp( con( cid ) , optIn( conVal ) );

export const cwse = ( cid : number ) : Optic< Design , undefined | Wise > =>
	comp( con( cid ) , optIn( conWse ) );

export const dlen = ( cid : number ) : Optic< Design , undefined | number > =>
	comp( con( cid ) , optIn( conLen ) , optIn( lval ) );

export const dang = ( cid : number ) : Optic< Design , undefined | number > =>
	comp( con( cid ) , optIn( conAng ) , optIn( aval ) );

export default Design;


export const design2simple = ( design : Design ) : Simple< number > => {
	const cons : Raw[] = cons2raws( 0 )( [ ...design.cons.data.values() ] );

	return {
		Selectors : cons.filter( isAng ).filter( c => c.degs !== 180 ).map( ang2sel ),
		Constraints : cons.map( con => isAng( con ) ? con.angl : con.dist ),
	};
};


export interface N {
	nid : number;
}

export interface C {
	cid : number;
	fst : number;
	fin : number;
	chk : Chunk< number >;
}

export interface R {
	cid : number;
	con : Con;
}

export interface L {
	cid : number;
	con : Con;
}

export interface F {
	fid : number;
}

export type Sketch = S.Sketch< number , number , number , V2 , N , C , R , L , F >;

export const basic2shape = ( basic : Basic< number > ) : Shape< number , Loose< HandleConfig< C > > > => basic.map( ( [ fst , chk ] , cid ) => {
	const fin : number = basic?.[ cid + 1 ]?.[ 0 ] ?? basic[ 0 ][ 0 ];
	const handle : Loose< HandleConfig< C > > = hextras( { cid , fst , fin , chk } );

	return [ fst , { ...chk , ...handle } ];
} ) as Shape< number , Loose< HandleConfig< C > > >;

export const basic2frame = ( basic : Basic< number > , fid : number ) : Frame< number , C , F > => {
	const shape : Shape< number , Loose< HandleConfig< C > > > = basic2shape( basic );
	const handle : Loose< HandleConfig< F > > = hextras( { fid } );

	return { shape , ...handle };
};

export const points2points = ( points : Index< P2 > ) : Map< number , Point< V2 , N > > => {
	const result : Map< number , Point< V2 , N > > = new Map();

	for ( const [ k , v ] of points.data )
		result.set( k , { point : p( v ) } );

	return result;
};

export const basics2frames = ( basics : Basic< number >[] ) : Map< number , Frame< number , C , F > > =>
	new Map( basics.map( ( b , i ) => [ i , basic2frame( b , i ) ] ) );


export const ang2rlr = ( ang : Ang ) => ( cid : number ) : Ruler< number , R , L , Angle< number > > => {
	const { degs , angl : { value : p } } = ang;
	const rhandle : Loose< HandleConfig< R > > = hextras( { cid , con : ang } );
	const lhandle : Loose< HandleConfig< L > > = hextras( { cid , con : ang } );
	const subject : Extent< number > = { first : p[0] , final : p[2] };
	const measure : Angle< number > = { root : p[1] , ...subject };
	const reading : Reading< L > = { unit : '°' , value : degs , ...lhandle };
	const spacing : number = 5;

	return { subject , measure , reading , spacing , ...rhandle };
};

export const len2rlr = ( len : Len ) => ( cid : number ) : Ruler< number , R , L , Length< number > > => {
	const { dist : { value : p } } = len;
	const rhandle : Loose< HandleConfig< R > > = hextras( { cid , con : len } );
	const lhandle : Loose< HandleConfig< L > > = hextras( { cid , con : len } );
	const subject : Extent< number > = { first : p[0] , final : p[1] };
	const measure : Length< number > = subject;
	const reading : Reading< L > = { unit : '"' , value : p[2] , ...lhandle };
	const spacing : number = 5;

	return { subject , measure , reading , spacing , ...rhandle };
};

export const rnd2rlr = ( idx : number ) => ( rnd : Rnd ) => ( cid : number ) : Ruler< number , R , L , Length< number > > => {
	const { radius } = rnd;

	const rhandle : Loose< HandleConfig< R > > = hextras( { cid , con : rnd } );
	const lhandle : Loose< HandleConfig< L > > = hextras( { cid , con : rnd } );
	const subject : Extent< number > = { first : idx + 2 , final : idx + 5 };
	const measure : Length< number > = subject;
	const reading : Reading< L > = { unit : '"' , value : radius , ...lhandle };
	const spacing : number = 0;

	return { subject , measure , reading , spacing , ...rhandle };
};

export const out2rlr = ( idx : number ) => ( out : Out ) => ( cid : number ) : Ruler< number , R , L , Length< number > > => {
	const { value , first , final } = out;

	const source : number = out.node === 'First' ? first : final;
	const target : number = idx;

	const rhandle : Loose< HandleConfig< R > > = hextras( { cid , con : out } );
	const lhandle : Loose< HandleConfig< L > > = hextras( { cid , con : out } );
	const subject : Extent< number > = { first : source , final : target };
	const measure : Length< number > = subject;
	const reading : Reading< L > = { unit : '"' , value , ...lhandle };
	const spacing : number = 5;

	return { subject , measure , reading , spacing , ...rhandle };
};

export const const2ruler = ( idx : number ) => ( con : Con ) : ( cid : number ) => Ruler< number , R , L > => {
	switch ( con.type ) {
	case 'Len':
		return len2rlr( con );
	case 'Ang':
		return ang2rlr( con );
	case 'Rnd':
		return rnd2rlr( idx )( con );
	case 'Out':
		return out2rlr( idx )( con );
	}
};

export const consts2rulers = ( ix : number ) => ( consts : Index< Con > ) : Map< number , Ruler< number , R , L > > => {
	let idx : number = ix;
	const result : Map< number , Ruler< number , R , L > > = new Map();

	for ( const [ k , c ] of consts.data ) {
		result.set( k , const2ruler( idx )( c )( k ) );
		idx = nextIdx( idx )( c );
	}

	return result;
};

export const design2sketch = ( design : Design ) : Sketch => {
	const points : Map< number , Point< V2 , N > > = points2points( design.pnts );
	const frames : Map< number , Frame< number , C , F > > = basics2frames( design.pics );
	const rulers : Map< number , Ruler< number , R , L > > = consts2rulers( 0 )( design.cons );

	return { points , frames , rulers };
};
