import { RefObject , useRef , useEffect , useState } from 'react';

/**
 * The Size return value specifying width and height.
 */
export interface Size {
	w : number;
	h : number;
}

/**
 * A hook used to report back width and height of the referenced object.
 */
export const useSize = <T extends Element >( outer ?: RefObject<T> ) : [ RefObject<T> , Size ] => {
	const ref : RefObject< T > = useRef< T >( outer ? outer.current : null );
	const [ size , setSize ] = useState< Size >( { w : 0 , h : 0 } );

	useEffect( () => {
		if ( !ref.current ) return;

		const callback = ( elem : ResizeObserverEntry ) : void =>
			setSize( { w : elem.contentRect.width , h : elem.contentRect.height } );

		const observer = new ResizeObserver( elems => elems.forEach( callback ) );

		observer.observe( ref.current );

		return () => observer.disconnect();
	} , [ ref ] );

	return [ ref , size ];
};

export default useSize;
